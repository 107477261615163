import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import Cross from '../assets/svgs/Cross';

export const RegistrationForm = ({ toggleRegistrationForm = () => {} }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    mobilePhone: '',
    email: '',
    postCode: '',
    hearAboutUs: ''
  });
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const recaptchaRef = useRef(null);

  const validateForm = () => {
    const newErrors = {};

    // Phone validation (Australian format)
    const phoneRegex = /^(?:\+?61|0)[2-478](?:[ -]?[0-9]){8}$/;
    if (!phoneRegex.test(formData.mobilePhone.replace(/\s/g, ''))) {
      newErrors.phone = 'Please enter a valid Australian phone number';
    }

    // Postcode validation (Australian format)
    const postcodeRegex = /^[0-9]{4}$/;
    if (!postcodeRegex.test(formData.postCode)) {
      newErrors.postCode = 'Please enter a valid 4-digit Australian postcode';
    }

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      newErrors.email = 'Please enter a valid email address';
    }

    // Terms validation
    if (!isChecked) {
      newErrors.terms = 'You must agree to the terms and conditions';
    }

    // reCAPTCHA validation
    if (!recaptchaToken) {
      newErrors.recaptcha = 'Please complete the reCAPTCHA verification';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    // Clear error when user starts typing
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
    if (errors.recaptcha) {
      setErrors(prev => ({
        ...prev,
        recaptcha: ''
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        setIsLoading(true);

        // Create form data for submission
        const formDataToSubmit = new URLSearchParams();
        formDataToSubmit.append('firstName', formData.firstName);
        formDataToSubmit.append('lastName', formData.lastName);
        formDataToSubmit.append('mobilePhone', formData.mobilePhone);
        formDataToSubmit.append('email', formData.email);
        formDataToSubmit.append('postCode', formData.postCode);

        // Map hearAboutUs to the specific field name required by the API
        formDataToSubmit.append('Answer0O1R5Z4K3E553G053M8R4X9H2R5J', formData.hearAboutUs);

        // Add reCAPTCHA token
        formDataToSubmit.append('g-recaptcha-response', recaptchaToken);

        // API endpoint from environment variable
        const apiUrl = `${process.env.REACT_APP_API_URL}/register`;

        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: formDataToSubmit,
        });

        if (response.ok) {
          setIsSubmitted(true);
          // Reset form
          setFormData({
            firstName: '',
            lastName: '',
            mobilePhone: '',
            email: '',
            postCode: '',
            hearAboutUs: ''
          });
          setIsChecked(false);
          setRecaptchaToken('');
          if (recaptchaRef.current) {
            recaptchaRef.current.reset();
          }

          // Close modal after 3 seconds on successful submission
          setTimeout(() => {
            toggleRegistrationForm();
          }, 3000);
        } else {
          const errorData = await response.json();
          alert(`Registration failed: ${errorData.message || 'Please try again.'}`);
        }
      } catch (error) {
        console.error('Registration error:', error);
        alert('An error occurred. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className='bg-gableGreen fixed h-screen w-full left-0 right-0 top-0 z-50 px-6 sm:px-10 xl:px-59 pt-10 overflow-y-auto'>
      <div className='w-full'>
        <div onClick={toggleRegistrationForm} className='ml-auto cursor-pointer w-fit'>
          <Cross className='w-5 sm:w-6 xl:w-8' />
        </div>
      </div>
      <div className="w-full sm:w-85 lg:w-70 mx-auto mt-5 sm:mt-10 pb-10">
        <h1 className="text-honeysuckle font-tobias font-medium text-4xl xl:text-5xl mb-6 text-center tracking-wide">Banyan Hill</h1>

        <p className="text-honeysuckle text-base sm:text-xl mb-10 text-center font-medium">
          Don't miss out, call today on <span className="text-sycamore font-semibold text-lg sm:text-xl hover:underline">1300 326 197</span>
          <br />or register your interest below.
        </p>

        {isSubmitted && (
          <div className="bg-sycamore bg-opacity-30 text-honeysuckle p-6 rounded-lg mb-10 w-full text-center shadow-lg">
            <h3 className="text-xl font-semibold mb-3">Thank you for registering your interest!</h3>
            <p className="text-base">A member of our team will be in touch with you shortly.</p>
          </div>
        )}

        <form onSubmit={handleSubmit} className="flex flex-col gap-8 sm:gap-10 mt-10 p-6 sm:p-8">
          <div className="flex flex-col sm:flex-row items-center gap-6 sm:gap-10">
            <div className="w-full">
              <input
                type="text"
                name="firstName"
                placeholder="First Name"
                value={formData.firstName}
                onChange={handleChange}
                required
                className="bg-transparent w-full pb-2 font-mori border-b-3 border-b-honeysuckle text-honeysuckle placeholder:text-honeysuckle/80 text-sm sm:text-base xl:text-lg outline-none focus:border-b-sycamore transition-colors"
              />
            </div>
            <div className="w-full">
              <input
                type="text"
                name="lastName"
                placeholder="Last Name"
                value={formData.lastName}
                onChange={handleChange}
                required
                className="bg-transparent w-full pb-2 font-mori border-b-3 border-b-honeysuckle text-honeysuckle placeholder:text-honeysuckle/80 text-sm sm:text-base xl:text-lg outline-none focus:border-b-sycamore transition-colors"
              />
            </div>
          </div>

          <div className="flex flex-col sm:flex-row items-center gap-6 sm:gap-10">
            <div className="w-full">
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                required
                className="bg-transparent w-full pb-2 font-mori border-b-3 border-b-honeysuckle text-honeysuckle placeholder:text-honeysuckle/80 text-sm sm:text-base xl:text-lg outline-none focus:border-b-sycamore transition-colors"
              />
              {errors.email && <span className="text-red-300 text-xs sm:text-sm mt-2 block font-medium">{errors.email}</span>}
            </div>
            <div className="w-full">
              <input
                type="tel"
                name="mobilePhone"
                placeholder="Phone"
                value={formData.mobilePhone}
                onChange={handleChange}
                required
                className="bg-transparent w-full pb-2 font-mori border-b-3 border-b-honeysuckle text-honeysuckle placeholder:text-honeysuckle/80 text-sm sm:text-base xl:text-lg outline-none focus:border-b-sycamore transition-colors"
              />
              {errors.phone && <span className="text-red-300 text-xs sm:text-sm mt-2 block font-medium">{errors.phone}</span>}
            </div>
          </div>

          <div className="flex flex-col sm:flex-row items-center gap-6 sm:gap-10">
            <div className="w-full">
              <input
                type="text"
                name="postCode"
                placeholder="Postcode (4 digits)"
                value={formData.postCode}
                onChange={handleChange}
                required
                maxLength="4"
                className="bg-transparent w-full pb-2 font-mori border-b-3 border-b-honeysuckle text-honeysuckle placeholder:text-honeysuckle/80 text-sm sm:text-base xl:text-lg outline-none focus:border-b-sycamore transition-colors"
              />
              {errors.postCode && <span className="text-red-300 text-xs sm:text-sm mt-2 block font-medium">{errors.postCode}</span>}
            </div>
            <div className="w-full">
              <select
                name="hearAboutUs"
                value={formData.hearAboutUs}
                onChange={handleChange}
                required
                className="bg-transparent w-full pb-2 font-mori border-b-3 border-b-honeysuckle text-honeysuckle text-sm sm:text-base xl:text-lg outline-none appearance-none cursor-pointer focus:border-b-sycamore transition-colors"
              >
                <option value="">How Did you Hear About Us?</option>
                <option value="Billboard">Billboard</option>
                <option value="Family/Friend Referral">Family/Friend Referral</option>
                <option value="Local News">Local News</option>
                <option value="REA Project Profile">REA Project Profile</option>
                <option value="Facebook">Facebook</option>
                <option value="Another Choice">Another Choice</option>
              </select>
            </div>
          </div>

          <div className="flex gap-6 items-center mt-4">
            <div className="grid place-items-center" onClick={() => {
              setIsChecked(!isChecked);
              if (errors.terms) {
                setErrors(prev => ({ ...prev, terms: '' }));
              }
            }}>
              <input
                type="radio"
                className="col-start-1 cursor-pointer row-start-1 appearance-none shrink-0 w-5 xl:w-6 h-5 xl:h-6 border-2 border-honeysuckle rounded-full hover:border-sycamore transition-colors"
              />
              {isChecked && <div className="col-start-1 row-start-1 w-3 xl:w-4 h-3 xl:h-4 rounded-full cursor-pointer bg-sycamore" />}
            </div>
            <label className="text-sm sm:text-base font-mori text-honeysuckle font-medium">
              I agree to the <Link className="underline text-sycamore hover:text-honeysuckle transition-colors">Privacy Policy</Link>
            </label>
          </div>
          {errors.terms && <span className="text-red-300 text-xs sm:text-sm mt-2 block font-medium">{errors.terms}</span>}

          <div className="mt-6 flex justify-center">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={handleRecaptchaChange}
              theme="dark"
            />
          </div>
          {errors.recaptcha && <span className="text-red-300 text-xs sm:text-sm mt-2 block font-medium text-center">{errors.recaptcha}</span>}

          <div className="mt-8 sm:mt-10 flex justify-center">
            <button
              type="submit"
              disabled={isLoading}
              className="border-2 border-honeysuckle hover:border-sycamore hover:bg-sycamore text-honeysuckle hover:text-gableGreen rounded-full text-sm sm:text-base xl:text-lg font-mori py-3 px-8 sm:px-12 cursor-pointer disabled:opacity-70 font-semibold transition-all duration-300 shadow-md hover:shadow-lg"
            >
              {isLoading ? 'Submitting...' : 'REGISTER NOW'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RegistrationForm;