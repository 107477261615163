import React from 'react';

const HomeFeatures = ({ beds, baths, cars }) => {
    return (
        <div className="flex gap-4">
            <div className="flex items-center gap-2">
                <span className="w-8 h-8 flex items-center justify-center bg-gableGreen text-white rounded-full font-mori font-medium">{beds}</span>
                <span className="font-mori text-gableGreen">Beds</span>
            </div>
            <div className="flex items-center gap-2">
                <span className="w-8 h-8 flex items-center justify-center bg-gableGreen text-white rounded-full font-mori font-medium">{baths}</span>
                <span className="font-mori text-gableGreen">Baths</span>
            </div>
            <div className="flex items-center gap-2">
                <span className="w-8 h-8 flex items-center justify-center bg-gableGreen text-white rounded-full font-mori font-medium">{cars}</span>
                <span className="font-mori text-gableGreen">Cars</span>
            </div>
        </div>
    );
};

export default HomeFeatures;