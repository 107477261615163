// Import images
import GJGardnerLogo from '../../assets/images/dh-gj-gardner-logo.jpg';
import PerryHomesLogo from '../../assets/images/dh-perry-homes-logo.jpg';
import CoralHomesLogo from '../../assets/images/dh-coral-homes-logo.png';

// Builder data
const buildersData = {
    gjgardner: {
        id: 'gjgardner',
        logo: GJGardnerLogo,
        name: 'GJ Gardner',
        description: "GJ Gardner, a multi award winning local business have built over 400 architecturally inspired homes. With a full design service available, they also offer a free site inspection to ensure their client's get the right advice to suit their family and budget.",
        homeName: 'Parkview',
        homeDescription: "The Parkview has been specifically designed for wider lots, its expansive frontage creating a magnificent visage. Exclusive features make a luxury statement throughout and include timber flooring, custom cabinetry, study nook, walk in pantry, linen and wardrobes with the master suite boasting his and hers walk in robes with ensuite and double vanity.",
        homeImages: [
            'https://banyanhill.com.au/full/wp-content/uploads/2020/01/GJG_Exterior_936x644.jpg',
            'https://banyanhill.com.au/full/wp-content/uploads/2020/01/GJG_Interior_1_936x644.jpg',
            'https://banyanhill.com.au/full/wp-content/uploads/2020/01/GJG_Interior_2_936x644.jpg',
            'https://banyanhill.com.au/full/wp-content/uploads/2020/01/GJG_Interior_3_936x644.jpg',
            'https://banyanhill.com.au/full/wp-content/uploads/2020/01/GJG_Interior_4_936x644.jpg',
            'https://banyanhill.com.au/full/wp-content/uploads/2020/01/GJG_Interior_5_936x644.jpg'
        ],
        beds: 4,
        baths: 3,
        cars: 2.5,
        contactInfo: "Book an appointment to view the GJ Gardner display home\nKane Munro 0411 740 878 or Robyn Frohlich 0439 390 309",
        openHours: "Open Friday to Tuesday, 10am to 4pm",
        buttonText: 'Find out more about GJ Gardner',
        buttonLink: 'https://www.gjgardner.com.au'
    },
    perryhomes: {
        id: 'perryhomes',
        logo: PerryHomesLogo,
        name: 'Perry Homes',
        description: "With a reputation for building quality homes in the North coast region, Perry Homes has received multiple industry awards. With an understanding of what it takes to create beautiful living spaces, clients can choose from 50+ home designs, 150 floorplans or work with Perry's inhouse team to design their own home.",
        homeName: 'Cosmo',
        homeDescription: "Synonymous with contemporary family living, the Cosmo is one of Perry's newest home designs offering the perfect synergy of space and style. Boasting two internal living areas, the modern kitchen includes a butler's pantry, generous cupboard spaces and impressive breakfast bar. Offering sensational value for money the Cosmo can be modified to meet your individual needs.",
        homeImages: [
            'https://banyanhill.com.au/full/wp-content/uploads/2020/06/11002_20200615_CR01_PerryHomesPhotos_Cosmo-1.jpg',
            'https://banyanhill.com.au/full/wp-content/uploads/2020/06/11002_20200615_CR01_PerryHomesPhotos_Cosmo-2.jpg',
            'https://banyanhill.com.au/full/wp-content/uploads/2020/06/11002_20200615_CR01_PerryHomesPhotos_Cosmo-3.jpg',
            'https://banyanhill.com.au/full/wp-content/uploads/2020/06/11002_20200615_CR01_PerryHomesPhotos_Cosmo-4.jpg',
            'https://banyanhill.com.au/full/wp-content/uploads/2020/06/11002_20200615_CR01_PerryHomesPhotos_Cosmo-5.jpg',
            'https://banyanhill.com.au/full/wp-content/uploads/2020/06/11002_20200615_CR01_PerryHomesPhotos_Cosmo-6.jpg',
            'https://banyanhill.com.au/full/wp-content/uploads/2020/06/11002_20200615_CR01_PerryHomesPhotos_Cosmo-7.jpg',
            'https://banyanhill.com.au/full/wp-content/uploads/2020/06/11002_20200615_CR01_PerryHomesPhotos_Cosmo-8.jpg',
            'https://banyanhill.com.au/full/wp-content/uploads/2020/06/11002_20200615_CR01_PerryHomesPhotos_Cosmo-9.jpg',
            'https://banyanhill.com.au/full/wp-content/uploads/2020/06/11002_20200615_CR01_PerryHomesPhotos_Cosmo-10.jpg'
        ],
        beds: 4,
        baths: 2,
        cars: 2,
        contactInfo: "While we accept walk-in traffic, we encourage you to book an appointment\nChris Littlewood 0411 611 842",
        openHours: "Open Saturday to Wednesday, 10am - 4pm",
        buttonText: 'Find out more about Perry Homes',
        buttonLink: 'https://www.perryhomes.com.au'
    },
    coralhomes: {
        id: 'coralhomes',
        logo: CoralHomesLogo,
        name: 'Coral Homes',
        description: "Coral Homes offer a range of new innovative home designs at an affordable price. The leading home builder has over 30 years' experience and is best known for their competitive prices and value for money. Whether you're buying your first home, looking for more room to raise a family or downsizing to something more manageable, Coral Homes will have a home design to suit your land and lifestyle.",
        homeName: 'Noosaville 25',
        homeDescription: "You'll be transported to a coastal oasis from the moment you are welcomed to the grand entrance of the Noosaville 25. This four-bedroom home boasts two comfortable living spaces and offers exceptional value whilst the indoor/outdoor integration is perfect for entertaining. A dreamy coastal home - one you won't want to miss!",
        homeImages: [
            'https://banyanhill.com.au/full/wp-content/uploads/2020/04/Noosaville-25-Ballina-Facade.jpg',
            'https://banyanhill.com.au/full/wp-content/uploads/2020/04/Noosaville-25-Ballina-Kitchen-2.jpg',
            'https://banyanhill.com.au/full/wp-content/uploads/2020/04/Noosaville-25-Ballina-Kitchen_Living.jpg',
            'https://banyanhill.com.au/full/wp-content/uploads/2020/04/Noosaville-25-Ballina-Kitchen.jpg',
            'https://banyanhill.com.au/full/wp-content/uploads/2020/04/Noosaville-25-Ballina-Living_Dining_Kitchen.jpg',
            'https://banyanhill.com.au/full/wp-content/uploads/2020/04/Noosaville-25-Ballina-Master-Bedroom.jpg',
            'https://banyanhill.com.au/full/wp-content/uploads/2020/04/Noosaville-25-Ballina-Ensuite.jpg'
        ],
        beds: 4,
        baths: 2,
        cars: 2,
        contactInfo: "The Coral Homes display home consultant may be contacted at:\nPhone 0409 516 970",
        openHours: "Open 7 Days, 10am to 5pm. No appointment necessary.",
        buttonText: 'Find out more about Coral Homes',
        buttonLink: 'https://www.coralhomes.com.au',
        virtualTourButton: {
            text: 'Click here for a virtual tour',
            link: 'https://my.matterport.com/show/?m=c7zgfazwCm1&kb=0&play=1'
        }
    }
};

export default buildersData;