import React, { useState, useEffect } from 'react'
import Button from '../Components/Button';
import LatestPosts from '../Components/LatestPosts';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../services/api';

const NewsArticle = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [article, setArticle] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchArticle = async () => {
            try {
                setLoading(true);
                const data = await api.getNewsById(id);
                setArticle(data);
                setLoading(false);
            } catch (err) {
                setError('Failed to load article');
                setLoading(false);
            }
        };

        fetchArticle();
    }, [id]);

    if (loading) {
        return <div className="flex justify-center items-center min-h-screen">Loading...</div>;
    }

    if (error || !article) {
        return <div className="flex justify-center items-center min-h-screen text-red-500">
            {error || 'Article not found'}
        </div>;
    }

    return (
        <>
            <section className='px-6 sm:px-10 xl:px-59 pb-8 sm:pb-12 lg:pb-24 xl:pb-28 mt-28 sm:mt-40'>
                <div className='w-full sm:w-3/4 lg:w-3/5 m-auto'>
                    {/* Title */}
                    <h1 className='font-tobias text-2xl sm:text-3xl lg:text-4xl xl:text-40 2xl:text-5xl text-gableGreen'>{article.title}</h1>
                    {/* Date */}
                    <p className='text-cuttySark font-medium font-mori text-xs lg:text-sm mt-2 md:mt-3 xl:mt-4'>{article.createdAt}</p>
                    {/* Image */}
                    <img src={article.image} alt='Featured Image' className='max-w-900 object-cover w-full mt-8 xl:mt-10' />
                    {/* Text */}
                    <div className='mt-8 sm:mt-12 lg:mt-20 lg:px-5 2xl:px-10'>
                        {/* <p className='text-sm lg:text-base xl:text-lg 2xl:text-xl font-mori font-medium text-gableGreen'>
                            {article.intro}
                        </p> */}
                        {article.content && (
                            <div
                                dangerouslySetInnerHTML={{ __html: article.content }}
                                className="mt-4 article-content text-gableGreen"
                            ></div>
                        )}
                        <Button
                            variant='gableGreen'
                            onClick={() => navigate(-1)}
                            className='mt-8 sm:mt-12 lg:mt-20 xl:mt-28 2xl:mt-32'
                            title='Back to news articles'
                        />
                    </div>
                </div>
            </section >
            {/* Latest Posts */}
            < LatestPosts />
            {/* Latest Posts */}
        </>
    )
}

const subHeading = 'text-xl sm:text-2xl lg:text-3xl 2xl:text-4xl font-tobias text-gableGreen mt-2 lg:mt-4 2xl:mt-6';
const firstPara = 'text-xs sm:text-sm xl:text-base 2xl:text-lg font-mori text-gableGreen mt-1 lg:mt-2 2xl:mt-4';
const secondPara = 'text-xs sm:text-sm xl:text-base 2xl:text-lg font-mori text-gableGreen mt-2 xl:mt-4';

export default NewsArticle