import { news } from '../utils/constants';
const NEWS_API_URL_ENDPOINT = `${process.env.REACT_APP_NEWS_API_URL}/banyanhill/news`;

const api = {
  getNews: async (page = 1) => {
    try {
      const response = await fetch(`${NEWS_API_URL_ENDPOINT}?page=${page}`);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return await response.json();
      // return news;
    } catch (error) {
      console.error('Error fetching news:', error);
      throw error;
    }
  },

  getNewsById: async (id) => {
    try {
      const response = await fetch(`${NEWS_API_URL_ENDPOINT}/${id}`);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      console.error(`Error fetching news with id ${id}:`, error);
      throw error;
    }
  }
};

export default api; 