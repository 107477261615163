import React, { useState, useRef, useEffect } from "react";
import { NavLink } from "react-router-dom";
import ArrowDown from "../assets/svgs/ArrowDown";

export const Dropdown = ({ dark, route }) => {
    const [isOpen, setIsOpen] = useState(false);
    const timeoutRef = useRef(null);

    const handleMouseEnter = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        setIsOpen(true);
    };

    const handleMouseLeave = () => {
        timeoutRef.current = setTimeout(() => {
            setIsOpen(false);
        }, 300);
    };

    useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    return (
        <div className="relative" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <NavLink
                to={route.path}
                className={({ isActive }) =>
                    [
                        "hover:underline text-base sm:text-lg lg:text-base 2xl:text-lg flex items-center gap-3 lg:gap-1 xl:gap-2 cursor-pointer",
                        isActive ? "underline" : "",
                    ].join(" ")
                }
            >
                {route.name}
                <ArrowDown />
            </NavLink>
            {isOpen &&
                <div
                    className={`lg:absolute origin-top-right focus:outline-none p-4 mt-2 min-w-max ${dark ? 'text-gableGree bg-parchment shadow-lg' : 'text-honeysuckle'}`}
                    role="menu"
                >
                    {route.options.map((item, i) => (
                        <NavLink
                            key={i}
                            to={item.path}
                            className={({ isActive }) =>
                                [
                                    "2xl:text-base hover:underline block pt-3 first:pt-0 font-mori",
                                    isActive ? "underline" : "",
                                ].join(" ")
                            }
                        >
                            {item.name}
                        </NavLink>)
                    )}
                </div>
            }
        </div>
    );
};

export default Dropdown;