import React from 'react';

const ImageCarousel = ({ images, currentIndex, onPrev, onNext, logo, name, homeName }) => {
    return (
        <div className="relative">
            {/* Navigation Arrows */}
            <button
                className="absolute left-4 top-1/2 transform -translate-y-1/2 z-10 bg-white w-10 h-10 flex items-center justify-center shadow-md hover:bg-gray-50 transition-colors"
                onClick={onPrev}
                aria-label="Previous image"
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-turquoise">
                    <path d="M15 18l-6-6 6-6" />
                </svg>
            </button>
            <button
                className="absolute right-4 top-1/2 transform -translate-y-1/2 z-10 bg-white w-10 h-10 flex items-center justify-center shadow-md hover:bg-gray-50 transition-colors"
                onClick={onNext}
                aria-label="Next image"
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-turquoise">
                    <path d="M9 18l6-6-6-6" />
                </svg>
            </button>

            {/* Image */}
            <div className="w-full h-0 pb-[68%] relative shadow-lg overflow-hidden">
                <img
                    src={images[currentIndex]}
                    alt={`${name} - ${homeName}`}
                    className="absolute top-0 left-0 w-full h-full object-cover"
                />
                <div className="absolute top-4 right-4">
                    <img src={logo} alt={name} className="h-40 w-auto object-contain" />
                </div>
            </div>
        </div>
    );
};

export default ImageCarousel;