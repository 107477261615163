const variants = {
    'polar': 'border-polar text-polar hover:text-gableGreen hover:border-gableGreen hover:bg-polar',
    'honeysuckle': 'border-honeysuckle hover:border-gableGreen hover:bg-gableGreen text-honeysuckle',
    'gableGreen': 'border-gableGreen hover:bg-gableGreen text-gableGreen hover:text-parchment',
    'turquoise': 'border-turquoise hover:bg-turquoise text-turquoise hover:text-white',
}

export const Button = ({ title = '', className = '', onClick = () => { }, variant = 'honeysuckle', disabled = false }) => {
    return (
        <button
            onClick={onClick}
            disabled={disabled}
            className={`border rounded-40 text-xs sm:text-sm xl:text-base 2xl:text-lg font-mori py-2 pb-[6px] px-4 sm:px-8 lg:px-4 2xl:px-5 ${variants[variant]} ${disabled ? 'opacity-60 cursor-not-allowed' : ''} ${className}`}
        >
            {title}
        </button>
    )
}

export default Button