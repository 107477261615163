import React from 'react';

const IntroSection = () => {
    return (
        <div className="flex flex-col md:flex-row gap-10">
            <div className="md:w-1/2">
                <h2 className="text-2xl sm:text-3xl lg:text-4xl font-tobias text-gableGreen mb-6">
                    Showcasing Four Luxury Homes
                </h2>
                <p className='text-base sm:text-lg xl:text-xl 2xl:text-2xl font-medium font-mori text-gableGreen leading-6 2xl:leading-8'>
                    Experience this stunning collection of the latest inspiring new home designs, from some of Australia's most reputable home builders. Set against our beautiful estate with ocean views and rolling hills, these homes showcase the latest in innovative, modern coastal living. We look forward to welcoming you to the Banyan Hill Display Village.
                </p>
                <p className="font-bold mt-6 text-gableGreen">
                    Lawrence Rise, Cumbalum NSW 2478
                </p>
            </div>
        </div>
    );
};

export default IntroSection;