import React from 'react';
import BuilderLogo from './BuilderLogo';

const BuilderSelector = ({ builders, selectedBuilder, onBuilderSelect }) => {
    return (
        <div className="flex flex-wrap justify-center gap-16 pb-12">
            {Object.entries(builders).map(([builderId, builder]) => (
                <BuilderLogo
                    key={builderId}
                    logo={builder.logo}
                    alt={builder.name}
                    isSelected={selectedBuilder === builderId}
                    onClick={() => onBuilderSelect(builderId)}
                />
            ))}
        </div>
    );
};

export default BuilderSelector;