import { permanentRedirect } from '../utils/globalFunctions';
import Button from './Button';

export const ByIntrapac = () => {
    return (
        <section className='bg-intrapac-property bg-no-repeat bg-cover bg-center flex justify-center items-center flex-col py-10 lg:py-20'>
            <h4 className='font-mori font-semibold text-parchment text-base sm:text-xl lg:text-2xl mb-2 sm:mb-3'>By Intrapac Property</h4>
            <p className='font-tobias text-parchment text-base sm:text-xl lg:text-2xl 2xl:text-3xl w-90 lg:w-70 xl:w-3/5 text-center'>
                Our commitment starts with the very best urban planners. It extends to EnviroDevelopment accreditation. It results in a community that is all about lifestyle and people, of everything that’s real and meaningful. It’s what we’ve been doing for more than 35 years, seen in the high-quality developments we have delivered everywhere from Victoria to Queensland, Western Australia to New South Wales and the Northern Territory.
            </p>
            <Button
                title='Find Out More'
                variant='polar'
                className='mt-4 sm:mt-7 text-sm 2xl:!text-base'
                onClick={() => permanentRedirect('https://intrapac.com.au/')}
            />
        </section>
    )
}

export default ByIntrapac