import React from 'react';
import Map from '../assets/images/masterplan.png';
import Nature from '../assets/images/nature.png';
import Family from '../assets/images/Family.png';
import Enviro from '../assets/images/enviro.png';
import {
  Card,
  Button,
  Banner,
  Accordion,
  ByIntrapac,
  LatestPosts,
  PageIntroSection
} from '../Components';
import { handleDownload, permanentRedirect } from '../utils/globalFunctions';

const About = () => {
    return (
        <>
            {/* Banner */}
            <Banner
                title={'About'}
                bgImage='bg-about-banner'
            />
            {/* Banner */}

            {/* Content */}
            <PageIntroSection
                text="True to its Northern NSW lifestyle, Banyan Hill is less about construction and more about evolution, appreciating what you have and preserving that beauty. Then environment, the values, the people and the essence of this region. Life's good up here, at Banyan Hill."
            >
                {/* Accordion */}
                <div className='w-full lg:w-1/2 mt-5 sm:mt-10 lg:mt-5'>
                    <Accordion title='View masterplan'>
                        <img src={Map} alt='Master Plan Map' className='max-w-830 object-contain w-full' />
                        <div className="flex flex-col md:flex-row items-center justify-between gap-5 mt-10 mb-5">
                            <Button
                                variant='gableGreen'
                                title='Download Masterplan'
                                className="order-2 md:order-1"
                                onClick={() => handleDownload("https://res.cloudinary.com/intrapac/image/upload/v1634618325/Project/5fbda9b0c41e2c0017347b7a/1634618319004-6dfb0dc0-3096-11ec-a122-6f5ee1481970")}
                            />
                            <p className="font-mori text-gableGreen md:w-3/5 order-1 md:order-2 text-sm sm:text-base">
                                This plan is an illustration for decorative purposes only. It is not to scale and should be used as a guide only. For actual dimensions of lots please refer to plan of subdivision. Subject planning approvals and change.
                            </p>
                        </div>
                    </Accordion>
                </div>
                {/* Accordion */}
                <div className={cardCotainer}>
                    <Card
                        image={Nature}
                        heading='Nature entices you to move here'
                        text={'Banyan Hill is 221Ha of just 750 lots. Being home to nature lovers, the blocks are deliberately oversized, up to 1,700 sqm each. Have your own native garden for the privacy you want and the peace you seek. Just listen to the birds. Or take in the views up here, looking out to a scene that changes everyday, depending on the time, the climate and the season.'}
                    />
                </div>
                <div className={cardCotainer}>
                    <Card
                        image={Family}
                        showImageFirst={false}
                        heading="The way it feels is why you'll stay"
                        text={'Wellbeing is everything, so in Banyan Hill there will be sportsground and facilities, with walking and cycling paths enabling families to leave the car at home. This is a place that makes it very easy to get around. Because happiness matters there will also be playgrounds where friendships are formed and kids have fun climbing, jumping or swinging. Even falling over, picking themselves up and laughing about it after.'}
                    />
                </div>
                <div className={cardCotainer}>
                    <Card
                        image={Enviro}
                        heading="Banyan Hill is an EnviroDevelopment"
                        text={'This independent assessment recognises property development with outstanding sustainability. To be certified as an EnviroDevelopment, Intrapac Property needed to achieve all six sustainability requirements and maintain them for the duration. To live in Banyan Hill is to know that your home is much more than a contemporary architect design, it is also sensitive to the surrounds and will continue to be so.'}
                    >
                        <Button
                            onClick={() => permanentRedirect('https://envirodevelopment.com.au/')}
                            title='Find Out More'
                            variant='gableGreen'
                            className='mt-5 xl:mt-10 text-sm 2xl:!text-base'
                        />
                    </Card>
                </div>
            </PageIntroSection>
            {/* Content */}

            {/* By Intrapac */}
            <ByIntrapac />
            {/* By Intrapac */}

            {/* Latest Posts */}
            <LatestPosts />
            {/* Latest Posts */}
        </>
    )
}
const cardCotainer = 'mt-8 sm:mt-10 lg:mt-16 xl:mt-20';

export default About