import React from 'react';
import Button from '../Button';

const BuilderDescription = ({
    description,
    homeName,
    homeDescription,
    buttonText,
    buttonLink,
    virtualTourButton
}) => {
    return (
        <div>
            <div className="flex items-start gap-6 mb-6">
                <div>
                    <p className="text-gableGreen font-mori text-base sm:text-xl leading-6 2xl:leading-8 font-medium">
                        {description}
                    </p>
                </div>
            </div>

            <h3 className="text-xl sm:text-2xl font-bold font-tobias text-gableGreen mb-4">{homeName}</h3>
            <p className="text-gableGreen font-mori text-base sm:text-xl leading-6 2xl:leading-8 mb-6">
                {homeDescription}
            </p>

            <div className="flex flex-wrap gap-4 mt-5 xl:mt-10">
                <Button
                    variant='turquoise'
                    title={buttonText}
                    onClick={() => window.open(buttonLink, '_blank')}
                    className='text-sm 2xl:!text-base'
                />

                {/* Conditional Virtual Tour Button */}
                {virtualTourButton && (
                    <Button
                        variant='turquoise'
                        title={virtualTourButton.text}
                        onClick={() => window.open(virtualTourButton.link, '_blank')}
                        className='text-sm 2xl:!text-base'
                    />
                )}
            </div>
        </div>
    );
};

export default BuilderDescription;