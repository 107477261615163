import React from 'react';

/**
 * PageIntroSection component - Common section to be used after the banner across multiple pages
 *
 * @param {Object} props
 * @param {string} props.text - The introductory paragraph text
 * @param {React.ReactNode} props.children - Additional content to be rendered below the intro text
 * @param {string} props.className - Additional CSS classes for the section
 * @param {string} props.textClassName - Additional CSS classes for the text paragraph
 * @returns {JSX.Element}
 */
export const PageIntroSection = ({
  text,
  children,
  className = '',
  textClassName = ''
}) => {
  return (
    <section className={`px-6 sm:px-10 xl:px-59 pt-8 sm:pt-12 lg:pt-14 xl:pt-20 pb-8 sm:pb-16 lg:pb-28 xl:pb-36 ${className}`}>
      {text && (
        <p className={`text-base sm:text-lg xl:text-xl 2xl:text-2xl font-medium font-mori lg:w-85 text-gableGreen leading-6 xl:leading-8 2xl:leading-10 pb-6 sm:pb-10 lg:pb-14 xl:pb-20 ${textClassName}`}>
          {text}
        </p>
      )}
      {children}
    </section>
  );
};

export default PageIntroSection;