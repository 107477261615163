import React from 'react';

const LandForSaleMap = () => {
  return (
    <div className="land-map-container" style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      margin: '0 auto',
      overflow: 'hidden',
      height: 'calc(100vh - 100px)',
      marginTop: '120px',
    }}>
      <iframe 
        src="https://map.banyanhill.com.au" 
        width="100%" 
        height="100%" 
        frameBorder="0" 
        style={{ border: 0 }} 
        title="Banyan Hill Land Map"
        allowFullScreen 
      />
    </div>
  );
};

export default LandForSaleMap;