import { useState } from 'react';
import Headline from '../Components/Headline';
import { Banner } from '../Components';

const BuildingResources = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Simulate a network request
    setTimeout(() => {
      if (password === 'builder') {
        setIsAuthenticated(true);
        setError('');
      } else {
        setError('Incorrect password. Please try again.');
      }
      setIsLoading(false);
    }, 500);
  };

  const ResourcesSection = ({ title, links }) => (
    <div className="p-6 rounded-lg mb-8 h-full w-full">
      <h3 className="text-2xl font-tobias font-semibold text-gableGreen mb-6 border-b border-gray-200">{title}</h3>
      <div className="mb-4">
        <div className="w-full h-[1px] bg-gray-500 rounded-full"></div>
      </div>
      <ul className="list-none pl-0 space-y-3">
        {links.map((link, index) => (
          <li key={index} className="flex items-start">
            {/* <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2 text-gableGreen mt-0.5 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
            </svg> */}
            <a
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
              className="hover:underline hover:text-blue-800 transition-colors duration-200"
            >
              {link.text}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );

  const buildingResources = [
    {
      title: "Stage One - Highfield Release One",
      links: [
        { text: "Design Guidelines (Stage 1A)", url: "https://banyanhill.com.au/full/wp-content/uploads/2019/08/BanyanHill_Design-Guidelines_Stage_1A.pdf" },
        { text: "Civil Design Drawing Set", url: "https://banyanhill.com.au/full/wp-content/uploads/2017/07/7771-Stage-1-CC-PRELIM.pdf" },
        { text: "Sales Plan (Stage 1A)", url: "https://banyanhill.com.au/full/wp-content/uploads/2017/07/16065-04-L-Sales-Plan-Banyan-Hill-Stage-1A-ISSUE-M.pdf" },
        { text: "Plan of Subdivision (Stage 1A) - Plan", url: "https://banyanhill.com.au/full/wp-content/uploads/2017/07/16065-32-DP-Stage-1A-Plan-Sheet-2.pdf" },
        { text: "Plan of Subdivision (Stage 1A) - Part 1 & 2", url: "https://banyanhill.com.au/full/wp-content/uploads/2017/07/Draft-S88B-Stage-1A.pdf" },
        { text: "Contour Plan", url: "https://banyanhill.com.au/full/wp-content/uploads/2017/07/Stage-1-Contour-Plan-2.pdf" },
        { text: "Disclosure Plans - Stage 1 Combined Plans", url: "https://banyanhill.com.au/full/wp-content/uploads/2017/07/Disclosure-Plans-Stage-One-Combined-Plans.pdf" },
        { text: "Statement of Environmental Affects", url: "https://banyanhill.com.au/full/wp-content/uploads/2017/07/DA-2016-576-Lodgement-Documents-Statement-of-Environmental-Effects-A....pdf" },
        { text: "Acoustics Report Sep 2016", url: "https://banyanhill.com.au/full/wp-content/uploads/2017/07/160901-EXP-BY-CRG-RE-ACOUSTICS-10191a-report-CURA-A-Sep-2016.pdf" },
        { text: "Bushfire Certifiers Threat Assessment Sept 2016", url: "https://banyanhill.com.au/full/wp-content/uploads/2017/07/7771-Bushfire-Certifiers-Bushfire-Threat-Assessment-Sept-2016.pdf" },
        { text: "Level 1 Geotechnical Inspection & Testing", url: "https://banyanhill.com.au/full/wp-content/uploads/2018/12/672824.00.R.001.Rev1_.Level-1-Report.pdf" },
        { text: "Tank location update", url: "https://banyanhill.com.au/full/wp-content/uploads/2019/04/Stage-1a-tank-Updated-Location-250319.pdf" }
      ]
    },
    {
      title: "Stage Two - Hilltop Release",
      links: [
        { text: "Design Guidelines", url: "https://banyanhill.com.au/full/wp-content/uploads/2019/08/BanyanHill_Design-Guidelines_Stage_2.pdf" },
        { text: "Stage Plan", url: "https://banyanhill.com.au/full/wp-content/uploads/2020/04/Stage-Two-Hilltop-Release-April.jpg" },
        { text: "Preliminary Earth Works Plan", url: "https://banyanhill.com.au/full/wp-content/uploads/2018/07/K2984-P072-06-Preliminary-Earth-Works-Plan.pdf" },
        { text: "Disclosure Plans (Issue B)", url: "https://banyanhill.com.au/full/wp-content/uploads/2018/12/16065-65-Disclosure-Plans-Stage-2-ISSUE-B-20181204.pdf" },
        { text: "Acoustics Report Sep 2016", url: "https://banyanhill.com.au/full/wp-content/uploads/2017/07/160901-EXP-BY-CRG-RE-ACOUSTICS-10191a-report-CURA-A-Sep-2016.pdf" },
        { text: "Draft Plan", url: "https://banyanhill.com.au/full/wp-content/uploads/2021/02/16065-66-DP-Stage-2-DRAFT-210204.pdf" },
        { text: "Stage 2 Geotechnical Level 1 Report", url: "https://banyanhill.com.au/full/wp-content/uploads/2021/03/672949.00.L.001.REV0_.pdf" },
        { text: "Neighbourhood Plan", url: "https://banyanhill.com.au/full/wp-content/uploads/2021/06/180921_FIGURE-3.7.1.2-NEIGHBOURHOOD-PLAN_STAGE-2.pdf" },
        { text: "As Constructed Drawings", url: "https://banyanhill.com.au/full/wp-content/uploads/2021/06/Stage-2-As-Constructed-Drawings.pdf" },
        { text: "Registered Deposited Plan", url: "https://banyanhill.com.au/full/wp-content/uploads/2021/06/Stage-2-Registered-Deposited-Plan-1274416.pdf" },
        { text: "Detention Tank Engineering Drawings", url: "https://banyanhill.com.au/full/wp-content/uploads/2021/06/Banyan-Hill-Detention-Tank-Engineering-Drawings.pdf" },
        { text: "Letter of Advice", url: "https://banyanhill.com.au/full/wp-content/uploads/2022/09/2022.06.17-Letter-of-advice-complying-development-Stage-2-Banyan-H....pdf" }
      ]
    },
    {
      title: "Stage Three - Fairview Release",
      links: [
        { text: "Design Guidelines", url: "https://banyanhill.com.au/full/wp-content/uploads/2019/08/BanyanHill_Design-Guidelines_Stage_3.pdf" },
        { text: "Fairview Release One", url: "https://banyanhill.com.au/full/wp-content/uploads/2018/11/BanyanHill-Stage3-ReleaseOne.pdf" },
        { text: "Fairview Release Two", url: "https://banyanhill.com.au/full/wp-content/uploads/2018/11/BanyanHill-Stage3-ReleaseTwo.pdf" },
        { text: "Disclosure Plans", url: "https://banyanhill.com.au/full/wp-content/uploads/2021/09/16065-92-Disclosure-Plans-Stage-3-LATEST-SET.pdf" },
        { text: "Road Traffic Noise Impact Assessment", url: "https://banyanhill.com.au/full/wp-content/uploads/2018/11/160901-EXP-BY-CRG-RE-ACOUSTICS-10191a-report-CURA-A-Sep-2016.pdf" },
        { text: "Acoustics Report Sep 2016", url: "https://banyanhill.com.au/full/wp-content/uploads/2018/11/160901-EXP-BY-CRG-RE-ACOUSTICS-10191a-report-CURA-A-Sep-2016.pdf" },
        { text: "Earthworks Plan", url: "https://banyanhill.com.au/full/wp-content/uploads/2021/02/Banyan-Hill-Stage-3-BEW-Drawings-20200804-1.pdf" },
        { text: "Neighbourhood Plan", url: "https://banyanhill.com.au/full/wp-content/uploads/2021/06/180921_FIGURE-3.7.1-NEIGHBOURHOOD-PLAN-MAP-STAGE-3.pdf" },
        { text: "Stage 3 Geotechnical Report", url: "https://banyanhill.com.au/full/wp-content/uploads/2021/06/Stage-3-Geotechnical-Rpt-672949.00.L.001.REV1_.pdf" },
        { text: "Ridgeview Release Disclosure Plans", url: "https://banyanhill.com.au/full/wp-content/uploads/2021/09/Ridgeview-Release-16065-220-Disclosure-Plans-Stages-3-4-12-Lots.pdf" },
        { text: "Plan of Subdivision", url: "https://banyanhill.com.au/full/wp-content/uploads/2021/09/Stage-3-POS-16065-90.pdf" },
        { text: "Bushfire Report", url: "https://banyanhill.com.au/full/wp-content/uploads/2022/03/Cumbalum-Ridge-CURA-A_Residential-Subdivision_Bushfire-Report_Rev-G-22.12.2017.pdf" },
        { text: "Registered Deposited Plan", url: "https://banyanhill.com.au/full/wp-content/uploads/2022/03/DP1273203-Stage-3-LRS-Registered.pdf" },
        { text: "As Constructed Drawings", url: "https://banyanhill.com.au/full/wp-content/uploads/2022/04/Stage-3-As-Constructed-Drawings.pdf" },
        { text: "Letter of Advice", url: "https://banyanhill.com.au/full/wp-content/uploads/2022/08/2022.02.09-Letter-of-advice-complying-development-Banyan-Hill-Stage-3-11840598_2.pdf" }
      ]
    },
    {
      title: "Stage Four - Ridgeline Release One",
      links: [
        { text: "Design Guidelines", url: "https://banyanhill.com.au/full/wp-content/uploads/2019/03/INTRA7513.6_BANYAN_HILL_DESIGN_GUIDELINES_STAGE_FOUR_UPDATES_DESIGN_2.0.pdf" },
        { text: "Disclosure Plans (Issue B)", url: "https://banyanhill.com.au/full/wp-content/uploads/2019/02/Disclosure_Plans_Stage_4-Release_1-ISSUE_B.pdf" },
        { text: "Neighbourhood Plan", url: "https://banyanhill.com.au/full/wp-content/uploads/2019/02/NEIGHBOURHOOD_PLAN-STAGE_4.pdf" },
        { text: "Street Frontage Map", url: "https://banyanhill.com.au/full/wp-content/uploads/2019/03/190208_FIGURE_3.2.1-STREET_FRONTAGE_MAP-STAGE_4.pdf" },
        { text: "Lot Frontages Type Map", url: "https://banyanhill.com.au/full/wp-content/uploads/2019/03/190208_FIGURE_3.3.1-LOT_FRONTAGES_TYPE_MAP_STAGE-4.pdf" },
        { text: "Acoustic Treatment Layout", url: "https://banyanhill.com.au/full/wp-content/uploads/2019/03/190208_FIGURE_3.5.1-ACOUSTIC_TREATEMENT_LAYOUT_STAGE_4.pdf" },
        { text: "Acoustics Report Sep 2016", url: "https://banyanhill.com.au/full/wp-content/uploads/2018/11/160901-EXP-BY-CRG-RE-ACOUSTICS-10191a-report-CURA-A-Sep-2016.pdf" },
        { text: "Mosquito Overlay Map", url: "https://banyanhill.com.au/full/wp-content/uploads/2019/03/190208_FIGURE_3.6.1-MOSQUITO_OVERLAY_MAP_STAGE_4.pdf" },
        { text: "Disclosure Plans", url: "https://banyanhill.com.au/full/wp-content/uploads/2019/05/Disclosure_Plans_Stage_4.pdf" },
        { text: "Bush Fire Overlay Map", url: "https://banyanhill.com.au/full/wp-content/uploads/2019/03/190208_FIGURE-3.4.1-BUSH-FIRE-OVERLAY-MAP_STAGE-4.pdf" },
        { text: "Ridgeview Release Disclosure Plans", url: "https://banyanhill.com.au/full/wp-content/uploads/2021/09/Ridgeview-Release-16065-220-Disclosure-Plans-Stages-3-4-12-Lots.pdf" },
        { text: "Report on Earthworks Inspection & Testing Level 1", url: "https://banyanhill.com.au/full/wp-content/uploads/2021/09/201980.00.R.001.REV0_.pdf" },
        { text: "Deposited Plan", url: "https://banyanhill.com.au/full/wp-content/uploads/2022/07/DP1278789-Stage-4-LRS-REGISTERED.pdf" },
        { text: "Letter of Advice", url: "https://banyanhill.com.au/full/wp-content/uploads/2022/07/2022.07.18-Letter-of-Advice-complying-development-Stage-4-Banyan-Hill-Development-12326907_2.pdf" }
      ]
    },
    {
      title: "Stages Three & Four - Ridgeview Release",
      links: [
        { text: "Neighbourhood Plan", url: "https://banyanhill.com.au/full/wp-content/uploads/2021/11/211102_BH_FIGURE-3.7.1.34-NEIGHBOURHOOD-PLAN-STAGE-34.pdf" },
        { text: "Road Frontages Map", url: "https://banyanhill.com.au/full/wp-content/uploads/2021/11/211102_BH_FIGURE-3.2.1.34-ROAD-FRONTAGES-MAP-STAGE-34.pdf" },
        { text: "Lot Frontage Types Map", url: "https://banyanhill.com.au/full/wp-content/uploads/2021/11/211102_BH_FIGURE-3.3.1.34-LOT-FRONTAGE-TYPES-MAP-STAGE-34.pdf" },
        { text: "Bushfire Hazard Map", url: "https://banyanhill.com.au/full/wp-content/uploads/2021/11/211102_BH_FIGURE-3.4.1.34-BUSHFIRE-HAZARD-MAP-STAGE-34.pdf" },
        { text: "Acoustic Control Map", url: "https://banyanhill.com.au/full/wp-content/uploads/2021/11/211102_BH_FIGURE-3.5.1.34-ACOUSTIC-CONTROL-MAP-STAGE-34.pdf" },
        { text: "Mosquito Treatments Map", url: "https://banyanhill.com.au/full/wp-content/uploads/2021/11/211102_BH_FIGURE-3.6.1.34-MOSQUITO-TREATMENTS-MAP-STAGE-34.pdf" },
        { text: "Design Guidelines", url: "https://banyanhill.com.au/full/wp-content/uploads/2021/11/13195_20211104_CR01_Banyan_Hill_Design_guidelines_Stage_34.pdf" },
        { text: "As Constructed Drawings", url: "https://banyanhill.com.au/full/wp-content/uploads/2022/04/Stage-4-As-Constructed-Drawings.pdf" },
        { text: "Disclosure Plans", url: "https://banyanhill.com.au/full/wp-content/uploads/2025/01/16065-220-Disclosure-Plans-Stages-3-4-12-Lots-UPDATED-211026.pdf" },
        { text: "Deposited Plan", url: "https://banyanhill.com.au/full/wp-content/uploads/2025/01/DP-1307736-Stage-3-4.pdf" }
      ]
    },
    {
      title: "Stage Five - Orchard Release",
      links: [
        { text: "Design Guidelines", url: "https://banyanhill.com.au/full/wp-content/uploads/2021/02/12231_20210216_CR02_Banyan_Hill_Design_guideline_update_Spreads_layout.pdf" },
        { text: "Disclosure Plans (Lots 501 - 526)", url: "https://banyanhill.com.au/full/wp-content/uploads/2022/05/16065-190-Disclosure-Plans-Stage-5.pdf" },
        { text: "Stage Plan", url: "https://banyanhill.com.au/full/wp-content/uploads/2021/02/16065-190-Sales-Plan-Stage-5-ISSUE-C-1.pdf" },
        { text: "Road Frontages Map", url: "https://banyanhill.com.au/full/wp-content/uploads/2021/09/210211_BH_FIGURE-3.2.1.5-ROAD-FRONTAGES-MAP-STAGE-5.pdf" },
        { text: "Lot Frontage Types Map", url: "https://banyanhill.com.au/full/wp-content/uploads/2021/09/210211_BH_FIGURE-3.3.1.5-LOT-FRONTAGE-TYPES-MAP-STAGE-5.pdf" },
        { text: "Bushfire Hazard Map", url: "https://banyanhill.com.au/full/wp-content/uploads/2021/09/210211_BH_FIGURE-3.4.1.5-BUSHFIRE-HAZARD-MAP-STAGE-5.pdf" },
        { text: "Mosquito Treatments Map", url: "https://banyanhill.com.au/full/wp-content/uploads/2021/09/210211_BH_FIGURE-3.6.1.5-MOSQUITO-TREATMENTS-MAP-STAGE-5.pdf" },
        { text: "Neighbourhood Plan Map", url: "https://banyanhill.com.au/full/wp-content/uploads/2021/09/210211_BH_FIGURE-3.7.1.5-NEIGHBOURHOOD-PLAN-MAP-STAGE-5.pdf" },
        { text: "Bushfire Overlay", url: "https://banyanhill.com.au/full/wp-content/uploads/2021/09/210211_BH_OVERLAY-3.4.1.5-BUSHFIRE-OVERLAY-STAGE-5.pdf" },
        { text: "Acoustics Control Map", url: "https://banyanhill.com.au/full/wp-content/uploads/2021/09/210921_BH_FIGURE-3.5.1.5-ACOUSTIC-CONTROL-MAP-STAGE-5.pdf" },
        { text: "As Constructed Drawings", url: "https://banyanhill.com.au/full/wp-content/uploads/2022/04/Stage-5-As-Constructed-Drawings.pdf" },
        { text: "Level 1 Earthworks Report", url: "https://banyanhill.com.au/full/wp-content/uploads/2022/06/Level-1-Earth-works-Report.pdf" },
        { text: "Attachment K DP Report for Earthworks", url: "https://banyanhill.com.au/full/wp-content/uploads/2022/06/Attachment-K-DP-Report-for-Earthworks.pdf" },
        { text: "Sales Plan", url: "https://banyanhill.com.au/full/wp-content/uploads/2022/06/16065-190-Sales-Plan-Stage-5-ISSUE-C.pdf" },
        { text: "Deposited Plan", url: "https://banyanhill.com.au/full/wp-content/uploads/2022/11/Deposited-Plan-Stage-5.pdf" },
        { text: "Letter of Advice", url: "https://banyanhill.com.au/full/wp-content/uploads/2022/11/Letter-of-Advice-in-stage-5-6.pdf" }
      ]
    },
    {
      title: "Stage Six - Crestbury Release",
      links: [
        { text: "Design Guidelines", url: "https://banyanhill.com.au/full/wp-content/uploads/2020/02/Banyall_Hill_Design-Guidelines_Stage_6.pdf" },
        { text: "Disclosure Plans (Lots 601 - 611)", url: "https://banyanhill.com.au/full/wp-content/uploads/2021/01/16065-157-Disclosure-Plans-Stage-6-Lots-601-611.pdf" },
        { text: "Disclosure Plans (Lots 612 - 615) Northern side", url: "https://banyanhill.com.au/full/wp-content/uploads/2021/01/16065-157-Disclosure-Plans-Stage-6-Lots-612-615-Northern-side-only.pdf" },
        { text: "As Constructed Drawings", url: "https://banyanhill.com.au/full/wp-content/uploads/2022/04/Stage-6-As-Constructed-Drawings.pdf" },
        { text: "Attachment T DP Report for Earthworks", url: "https://banyanhill.com.au/full/wp-content/uploads/2022/06/Attachment-T-DP-Report-for-Earthworks.pdf" },
        { text: "Letter of Advice", url: "https://banyanhill.com.au/full/wp-content/uploads/2022/11/Letter-of-Advice-in-stage-5-6.pdf" }
      ]
    },
    {
      title: "Stage Ten - Havensview Release",
      links: [
        { text: "Design Guidelines", url: "https://banyanhill.com.au/full/wp-content/uploads/2020/07/Banyan-Hill-Stage-10-Design-Guidelines.pdf" },
        { text: "Stage Plan", url: "https://banyanhill.com.au/full/wp-content/uploads/2020/09/16065-174-A-Sales-Plan-Stage-10A.pdf" },
        { text: "Disclosure Plans (Lots 1001 - 1030)", url: "https://banyanhill.com.au/full/wp-content/uploads/2020/12/16065-170-F-Disclosures-Stage-10-Lots-1001-1030-1.pdf" },
        { text: "Disclosure Plans (Lots 1041 - 1062)", url: "https://banyanhill.com.au/full/wp-content/uploads/2020/12/16065-170-G-Disclosures-Stage-10A-1041-1062-1.pdf" },
        { text: "Neighbourhood Plan", url: "https://banyanhill.com.au/full/wp-content/uploads/2021/01/Neighbourhood-Plan-Stage-10.pdf" }
      ]
    },
    {
      title: "Performance Rebate",
      links: [
        { text: "Performance Rebate Application PDF", url: "https://banyanhill.com.au/full/wp-content/uploads/2019/12/INT1951-BH-Performance-Rebate-Application-Soft.pdf" }
      ]
    },
    {
      title: "Masterplan",
      links: [
        { text: "Masterplan with Street Names", url: "https://banyanhill.com.au/full/wp-content/uploads/2021/09/16065-80-G-Proposed-Road-Names-1.pdf" }
      ]
    },
    {
      title: "General Estate Documents",
      links: [
        { text: "Bush Fire Report", url: "https://banyanhill.com.au/full/wp-content/uploads/2018/11/Cumbalum-Ridge-CURA-A_Residential-Subdivision_Bushfire-Report_Rev-G-22.1.._.pdf" },
        { text: "Mosquito Management", url: "https://banyanhill.com.au/full/wp-content/uploads/2018/07/DA-2016_184-Technical-Report-Mosquito-Assessment.pdf" }
      ]
    }
  ];

  return (
    <>
      <Banner
        title={'Building Resources'}
        bgImage='bg-building-resources-banner'
      />

      <div className="container mx-auto py-16 px-4 md:px-8">
        {!isAuthenticated ? (
          <div className="max-w-md mx-auto p-8">
            <h2 className="text-3xl font-tobias font-semibold text-gableGreen mb-6">Building Resources Portal</h2>
            <p className="mb-6 text-gray-600">
              Please enter the password to access the Building Resources portal.
            </p>

            {error && (
              <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4" role="alert">
                <span className="block sm:inline">{error}</span>
              </div>
            )}

            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label htmlFor="password" className="block text-gray-700 text-sm font-bold mb-2">
                  Password
                </label>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="appearance-none border rounded-full w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Enter password"
                  required
                />
              </div>
              <button
                type="submit"
                disabled={isLoading}
                className="bg-gableGreen hover:bg-green-800 text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline w-full flex justify-center items-center"
              >
                {isLoading ? (
                  <>
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Logging in...
                  </>
                ) : (
                  'Login'
                )}
              </button>
            </form>
          </div>
        ) : (
          <div>
            <div className="mb-10 text-center">
              <h2 className="text-4xl font-tobias font-bold mb-4 text-gableGreen">Banyan Hill Building Resources</h2>
              <p className="max-w-2xl mx-auto text-gray-600">
                You can access and download stage information by clicking on the links below.
                If you have any queries, please contact the Banyan Hill sales team on <a href="tel:+611300326197" className="text-blue-600 hover:underline">1300 326 197</a>.
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {buildingResources.map((section, index) => (
                <div key={index} className="h-full flex">
                  <ResourcesSection
                    title={section.title}
                    links={section.links}
                  />
                </div>
              ))}
            </div>

            <div className="mt-10 text-center">
              <p className="text-sm text-gray-500">
                © Banyan Hill {new Date().getFullYear()} - Building Resources Portal
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default BuildingResources;