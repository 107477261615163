import News1 from '../assets/images/news_1.png';
import News2 from '../assets/images/news_2.png';
import News3 from '../assets/images/news_3.png';
import News4 from '../assets/images/news_4.png';
import News5 from '../assets/images/news_5.png';
import News6 from '../assets/images/news_6.png';
import News7 from '../assets/images/news_7.png';

export const findOptions = ["I'd like to find about Land", "I'd like to find about House & Land"];
export const buyOptions = ["I'm looking to buy <1 month", "I'm looking to buy <6 months", "I'm looking to buy <12 months", "I'm looking to buy <24 months", "I'm looking to buy >24 months"];

export const buyerTypes = ['First Homebuyer', 'Upgrader', 'Downsizer', 'Investor'];

export const routes = [
    {
        name: 'About',
        type: 'select',
        path: '/about',
        options: [
            {
                name: 'Masterpaln',
                path: '/masterplan',
            }
        ]
    },
    {
        name: 'Now Selling',
        type: 'select',
        path: '/now-selling',
        options: [
            {
                name: 'Land for Sale',
                path: '/land-for-sale',
            },
            {
                name: 'Display Homes',
                path: '/display-homes',
            }
        ]
    },
    {
        name: 'The Life',
        type: 'link',
        path: '/the-life'
    },
    {
        name: 'Progress',
        type: 'link',
        path: '/progress'
    },
    {
        name: 'News',
        type: 'link',
        path: '/news'
    }
];

export const Facts = [
    '10 minutes from historic Ballina',
    'Pedestrian-friendly',
    '15 minutes to Lennox Head',
    '12km of walking and bike paths',
    'Half an hour from Byron Bay',
    'Envirodevelopent credentials',
    '221Ha of striking topography',
    'Famous aged-old Moreton Bay Fig',
    'Established lush landscape',
    'Life revolves around a Village Green*',
    'Jaw-dropping 360-degree views',
    'Sportsground* for future home games',
    'Overlooking ocean and hinterland',
    'Multi-purposed Community Centre*',
    '750 lots of different sizes',
    'Flourishing Community Garden*',
    'Some blocks up to 1700sqm',
    'Every amenity just down the hill'
];

export const news = [
    {
        createdAt: '12 May 2023',
        latest: true,
        image: News1,
        title: 'This is the latest article title',
        intro: 'This is the intro copy. This is the copy that will go into this section. Tis repedit quossint eatume nim volori aut vel inulpa as est fugita volupta qui nis dolupta nus, consequas reribus sunt aligendit ut ut enit alignihitium eatiusa ndellab oresedi gendigendi dolo vero ipiendae. Modipsandae volo.',
        id: '0'
    },
    {
        createdAt: '11 May 2023',
        image: News2,
        title: 'This is the article title 1',
        intro: 'This is the intro copy. This is the copy that will go into this section.',
        id: '1'
    },
    {
        createdAt: '11 May 2023',
        image: News3,
        title: 'This is the article title 2',
        intro: 'This is the intro copy. This is the copy that will go into this section.',
        id: '2'
    },
    {
        createdAt: '11 May 2023',
        image: News4,
        title: 'This is the article title 3',
        intro: 'This is the intro copy. This is the copy that will go into this section.',
        id: '3'
    },
    {
        createdAt: '11 May 2023',
        image: News5,
        title: 'This is the article title 4',
        intro: 'This is the intro copy. This is the copy that will go into this section.',
        id: '4'
    },
    {
        createdAt: '11 May 2023',
        image: News6,
        title: 'This is the article title 5',
        intro: 'This is the intro copy. This is the copy that will go into this section.',
        id: '5'
    },
    {
        createdAt: '11 May 2023',
        image: News7,
        title: 'This is the article title 6',
        intro: 'This is the intro copy. This is the copy that will go into this section.',
        id: '6'
    },
];

export const stage1Steps = [
    { label: 'Plans & Permit', status: 'completed', color: 'gableGreen' },
    { label: 'Earthworks', status: 'completed', color: 'gableGreen' },
    { label: 'Sewer and Drainage', status: 'completed', color: 'gableGreen' },
    { label: 'Road Boxing, Kerb and Channel', status: 'in-progress' },
    { label: 'Electrical and Communication Supply', status: 'upcoming' },
    { label: 'Footpaths, Asphalt and Line marking', status: 'upcoming' },
    { label: 'Completion and Inspection of works', status: 'upcoming' },
    { label: 'Title and Settlements', status: 'upcoming' },
    { label: '', color: 'sycamore' },
    { label: '', color: 'sycamore' },
    { label: '', color: 'sycamore' },
];

export const stage2Steps = [
    { label: 'Plans & Permit', status: 'in-progress' },
    { label: 'Earthworks', status: 'upcoming' },
    { label: 'Sewer and Drainage', status: 'upcoming' },
    { label: 'Road Boxing, Kerb and Channel', status: 'upcoming' },
    { label: 'Electrical and Communication Supply', status: 'upcoming' },
    { label: 'Footpaths, Asphalt and Line marking', status: 'upcoming' },
    { label: 'Completion and Inspection of works', status: 'upcoming' },
    { label: 'Title and Settlements', status: 'upcoming' },
    { label: '', color: 'sycamore' },
    { label: '', color: 'sycamore' },
    { label: '', color: 'sycamore' },
];