export const InputField = ({ placeholder = '', variant = 'polar', value = '', onChange = () => {} }) => {
    const variants = {
        'polar': 'placeholder:text-polar border-b-polar text-polar',
        'honeysuckle': 'placeholder:text-honeysuckle border-b-honeysuckle text-honeysuckle',
    }
    return (
        <input
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            className={`bg-transparent w-full pb-1 font-mori border-b-3 text-xs sm:text-sm xl:text-base outline-none ${variants[variant]}`}
        />
    )
}

export default InputField