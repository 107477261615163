import React from 'react';

const variants = {
    'polar': {
        input: 'border-polar',
        checked: 'bg-polar'
    },
    'honeysuckle': {
        input: 'border-honeysuckle',
        checked: 'bg-honeysuckle'
    },
}

export const Radio = ({ variant = 'polar', checked = false, onChange = () => {} }) => {
    return (
        <div className="grid place-items-center" onClick={onChange}>
            <input
                type="radio"
                className={`col-start-1 cursor-pointer row-start-1 appearance-none shrink-0 w-4 xl:w-5 h-4 xl:h-5 border-2 rounded-full ${variants[variant]?.input}`}
                checked={checked}
                onChange={() => {}}
            />
            {checked && <div className={`col-start-1 row-start-1 w-2 xl:w-3 h-2 xl:h-3 rounded-full cursor-pointer ${variants[variant]?.checked}`} />}
        </div>
    )
}

export default Radio