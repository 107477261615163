import React, { createContext, useContext, useState } from 'react';
import RegistrationForm from './RegistrationForm';

// Create a context for the registration form
const RegistrationFormContext = createContext();

// Hook to use the registration form context
export const useRegistrationForm = () => {
  const context = useContext(RegistrationFormContext);
  if (!context) {
    throw new Error('useRegistrationForm must be used within a RegistrationFormProvider');
  }
  return context;
};

// Provider component that wraps your app and makes registration form state available to any
// child component that calls useRegistrationForm()
export const RegistrationFormProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleRegistrationForm = () => {
    setIsOpen(prev => !prev);
  };

  return (
    <RegistrationFormContext.Provider value={{ toggleRegistrationForm }}>
      {children}
      {isOpen && <RegistrationForm toggleRegistrationForm={toggleRegistrationForm} />}
    </RegistrationFormContext.Provider>
  );
};

export default RegistrationFormProvider;