import React, { useState } from 'react';
import ArrowDown from '../assets/svgs/ArrowDown';

export const Accordion = ({ title = '', children }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    return (
        <div className='w-full border border-gableGreen border-x-0 py-1 2xl:py-2 cursor-pointer'>
            <div className='flex items-center justify-between gap-4' onClick={() => setIsExpanded(!isExpanded)}>
                <p className='font-mori font-semibold text-sm sm:text-base 2xl:text-xl text-gableGreen'>{title}</p>
                <ArrowDown className={`sm:h-8 sm:w-8 ${isExpanded ? 'rotate-180' : ''}`} />
            </div>
            {isExpanded && children}
        </div>
    )
}

export default Accordion;