import Location from '../assets/images/google_map.png';

const ContactUs = () => {
    return (
        <section className='flex flex-col sm:flex-row gap-8 lg:gap-16 px-6 sm:px-10 xl:px-59 pb-12 md:pb-16 xl:pb-24 2xl:pb-28 mt-28 sm:mt-44'>
            <div className='w-full sm:w-1/2'>
                <h1 className='font-tobias text-2xl sm:text-3xl lg:text-4xl xl:text-40 2xl:text-5xl text-gableGreen'>Contact us</h1>
                <p className="font-mori text-sm lg:text-base 2xl:text-lg text-gableGreen mt-3 sm:mt-4 lg:mt-6 xl:mt-8 leading-5 lg:leading-6 font-medium">
                    Pop in next time you’re passing. Our Sales Centre at Ballina Heights Drive Cumbalum is open seven days a week, 11am-5pm, excluding public holidays. Even better, call us to make an appointment and we’ll make sure the coffee’s on.
                </p>
                <div className='hidden sm:block mt-6'>
                    <p className={contactDetails}>1300 326 197</p>
                    <p className={`${contactDetails} my-2 xl:my-3`}>info@banyanhill.com.au</p>
                    <p className={contactDetails}>Banyan Hill Sales Centre <br /> Ballina Heights Drive <br /> Cumbalum  NSW 2478</p>
                </div>
            </div>
            <div className="w-full sm:w-1/2">
                <img src={Location} alt='Location' className='object-contain w-full max-w-720' />
            </div>
            <div className='block sm:hidden'>
                <p className={contactDetails}>1300 326 197</p>
                <p className={`${contactDetails} my-1`}>info@banyanhill.com.au</p>
                <p className={contactDetails}>Banyan Hill Sales Centre <br /> Ballina Heights Drive <br /> Cumbalum  NSW 2478</p>
            </div>
        </section >
    )
}
const contactDetails = "font-mori text-xs xl:text-sm font-semibold text-gableGreen";

export default ContactUs