import Views from '../assets/images/views.png';
import Beaches from '../assets/images/beaches.png';
import Video from '../assets/images/home_video.png';
import TownFood from '../assets/images/town_food.png';
import TownRoad from '../assets/images/town_road.png';
import Hinterland from '../assets/images/hinterland.png';
import { useNavigate } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';
import { useRegistrationForm } from '../Components/RegistrationFormProvider';
import {
  Button,
  Banner,
  LatestPosts,
  PageIntroSection
} from '../Components';

const Home = () => {
  const navigate = useNavigate();
  const { toggleRegistrationForm } = useRegistrationForm();
  const heading = 'font-semibold font-mori text-sm sm:text-base xl:text-xl text-gableGreen';
  const paragraph = 'font-tobias text-xl sm:text-2xl xl:text-4xl text-gableGreen leading-6 sm:leading-7 xl:leading-10 pt-2 sm:pt-3 xl:pt-5';
  return (
    <>
      {/* Banner */}
      <div>
        <section className='bg-home-banner bg-no-repeat bg-cover bg-center h-screen px-6 sm:px-10 xl:px-59 flex justify-end flex-col pb-70'>
          <p className='font-tobias text-honeysuckle text-7xl sm:text-88 lg:text-116 xl:text-149'>Life's good up here</p>
          <div className='flex items-center gap-3 sm:gap-5 mt-6'>
            <Button title='View Masterplan' onClick={() => navigate('/masterplan')} />
            <Button title='Available Lots' onClick={() => navigate('/land-for-sale')} />
            <Button title='Register Interest' onClick={toggleRegistrationForm} variant='honeysuckle' />
          </div>
        </section>
        <div className='top-0 w-full absolute bg-gradient-to-b h-60 from-lightBlack to-transparent' />
      </div>
      {/* Banner */}

      {/* Content */}
      <PageIntroSection
        text="There's something magical about this region. But it can feel like it's slipping out of reach. Until now. Welcome to a place where the coastal dream lives on. A new home that has it all. The views. The benches. The towns. The hinterland. Everything you've always wanted. High above Lennox and Ballina. Life's good up here, at Banyan Hill."
      >
        {/* Video */}
        <ScrollAnimation animateIn="fadeIn">
          <img src={Video} alt='video' className='w-full max-h-740 m-auto object-cover' />
        </ScrollAnimation>
        {/* Video */}

        {/* Views */}
        <div className='flex flex-col sm:flex-row justify-between gap-6 sm:gap-2 pt-8 sm:pt-10'>
          <ScrollAnimation animateIn="fadeInLeft" className='sm:w-2/4 lg:w-5/12'>
            <p className={heading}>The views</p>
            <p className={paragraph}>
              Up in the hills with views all around  you'll feel away from it all and connected to nature in a way only living up high can ever really offer.
            </p>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInRight" className='w-1/2 sm:w-fit ml-auto'>
            <img src={Views} alt='views' className='max-h-72 xl:max-h-96 object-cover w-full' />
          </ScrollAnimation>
        </div>
        {/* Views */}

        {/* Beaches */}
        <div className='pt-8 sm:pt-12 lg:pt-14 xl:pt-20'>
          <ScrollAnimation animateIn="bounceIn" className='lg:w-90'>
            <img src={Beaches} alt='beaches' className='max-h-1594 object-cover w-full' />
          </ScrollAnimation>
          <ScrollAnimation animateIn="bounceInLeft" className='w-90 sm:w-1/2 lg:w-5/12 pt-8 sm:pt-12 lg:pt-14 xl:pt-20'>
            <p className={heading}>The beaches</p>
            <p className={paragraph}>
              Live just 10 minutes from Ballina, 15 to Lennox or 30 to Byron. And with ocean views you'll always feel connected to the water.
            </p>
          </ScrollAnimation>
        </div>
        {/* Beaches */}

        {/* Town */}
        <ScrollAnimation animateIn="bounceInRight" className='flex flex-col sm:flex-row gap-8 xl:gap-10 pt-10 sm:pt-16 lg:pt-32 xl:pt-48'>
          <div className='sm:pl-8 w-1/2 sm:w-fit'>
            <img src={TownFood} alt='Town food' className='max-h-96 xl:max-h-500 object-cover w-64 lg:w-full' />
          </div>
          <div className='pl-12 sm:pl-0 sm:w-1/2 lg:w-2/5'>
            <p className={heading}>The towns</p>
            <p className={paragraph}>
              Ballina or Lennox are a short trip down the hill with everything you've come to expect from charming and contemporary coastal towns in this part of the world.
            </p>
          </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn="bounceInUp" className='flex gap-4 sm:gap-8 xl:gap-10 mt-8 sm:mt-10 xl:mt-14'>
          <div className='sm:w-1/4 lg:w-2/5' />
          <div className='w-full sm:w-3/4 lg:w-3/5'>
            <img src={TownRoad} alt='Town road' className='object-cover max-h-550 ml-auto w-full' />
          </div>
        </ScrollAnimation>
        {/* Town */}

        {/* Hinterland */}
        <ScrollAnimation animateIn="fadeInBottomLeft" className='flex flex-col sm:flex-row gap-8 xl:gap-10 mt-12 sm:mt-20 lg:mt-32 xl:mt-48'>
          <div className='w-1/2 sm:w-fit ml-auto sm:ml-0'>
            <img src={Hinterland} alt='Hinterland' className=' w-64 lg:w-full max-h-96 xl:max-h-500 object-cover' />
          </div>
          <div className='w-85 sm:w-2/5'>
            <p className={heading}>The hinterland</p>
            <p className={paragraph}>
              Be on the doorstep of the magical hinterland that will draw you in and keep you coming back again and again for its natural beauty.
            </p>
          </div>
        </ScrollAnimation>
        {/* Hinterland */}
      </PageIntroSection>
      {/* Content */}

      {/* Latest Posts */}
      <LatestPosts />
      {/* Latest Posts */}
    </>
  )
}

export default Home