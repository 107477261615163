import React, { useState, useRef } from 'react';
import Radio from './Radio';
import Button from './Button';
import Select from './Select';
import InputField from './InputField';
import { Link } from 'react-router-dom';
import Cross from '../assets/svgs/Cross';
import { buyerTypes, buyOptions, findOptions } from '../utils/constants';
import ReCAPTCHA from 'react-google-recaptcha';

export const EnquireForm = ({ toggleEnquireForm = () => { } }) => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        findAbout: findOptions[0],
        buyTimeline: buyOptions[0],
        buyerType: 'Buyer Type',
        message: ''
    });
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [recaptchaToken, setRecaptchaToken] = useState('');
    const recaptchaRef = useRef(null);

    const handleInputChange = (field, value) => {
        setFormData(prev => ({
            ...prev,
            [field]: value
        }));
        // Clear error when user starts typing
        if (errors[field]) {
            setErrors(prev => ({
                ...prev,
                [field]: ''
            }));
        }
    };

    const handleRecaptchaChange = (token) => {
        setRecaptchaToken(token);
        if (errors.recaptcha) {
            setErrors(prev => ({
                ...prev,
                recaptcha: ''
            }));
        }
    };

    const validateForm = () => {
        const newErrors = {};

        // Email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.email)) {
            newErrors.email = 'Please enter a valid email address';
        }

        // Phone validation (basic)
        const phoneRegex = /^[0-9+\s()-]{8,15}$/;
        if (!phoneRegex.test(formData.phone)) {
            newErrors.phone = 'Please enter a valid phone number';
        }

        // Required fields
        if (!formData.firstName.trim()) {
            newErrors.firstName = 'First name is required';
        }

        if (!formData.lastName.trim()) {
            newErrors.lastName = 'Last name is required';
        }

        // Buyer Type validation
        if (formData.buyerType === 'Buyer Type') {
            newErrors.buyerType = 'Please select a buyer type';
        }

        // Terms validation
        if (!isChecked) {
            newErrors.terms = 'You must agree to the terms and conditions';
        }

        // reCAPTCHA validation
        if (!recaptchaToken) {
            newErrors.recaptcha = 'Please complete the reCAPTCHA verification';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setIsSubmitting(true);

            try {
                // Create form data for submission
                const formDataToSubmit = new URLSearchParams();
                formDataToSubmit.append('firstName', formData.firstName);
                formDataToSubmit.append('lastName', formData.lastName);
                formDataToSubmit.append('email', formData.email);
                formDataToSubmit.append('phone', formData.phone);
                formDataToSubmit.append('findAbout', formData.findAbout);
                formDataToSubmit.append('buyTimeline', formData.buyTimeline);
                formDataToSubmit.append('buyerType', formData.buyerType);
                formDataToSubmit.append('message', formData.message);
                formDataToSubmit.append('formSource', 'Main');

                // Add reCAPTCHA token
                formDataToSubmit.append('g-recaptcha-response', recaptchaToken);

                // API endpoint from environment variable
                const apiUrl = `${process.env.REACT_APP_API_URL}/enquire`;

                const response = await fetch(apiUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: formDataToSubmit,
                });

                if (response.ok) {
                    // Success handling
                    setIsSubmitted(true);
                    setIsSubmitting(false);

                    // Reset form
                    setFormData({
                        firstName: '',
                        lastName: '',
                        email: '',
                        phone: '',
                        findAbout: findOptions[0],
                        buyTimeline: buyOptions[0],
                        buyerType: 'Buyer Type',
                        message: ''
                    });
                    setIsChecked(false);
                    setRecaptchaToken('');
                    if (recaptchaRef.current) {
                        recaptchaRef.current.reset();
                    }

                    // Reset form after 3 seconds or close it
                    setTimeout(() => {
                        toggleEnquireForm();
                    }, 3000);
                } else {
                    const errorData = await response.json();
                    console.error('Form submission error:', errorData);
                    setIsSubmitting(false);
                    setErrors(prev => ({
                        ...prev,
                        general: errorData.message || 'Something went wrong. Please try again.'
                    }));
                }
            } catch (error) {
                console.error('Form submission error:', error);
                setIsSubmitting(false);
                setErrors(prev => ({
                    ...prev,
                    general: 'Something went wrong. Please try again.'
                }));
            }
        }
    };

    return (
        <div className='bg-gableGreen fixed h-screen left-0 right-0 z-20 px-6 sm:px-10 xl:px-59 pt-10 overflow-y-auto'>
            <div className='w-full'>
                <div onClick={toggleEnquireForm} className='ml-auto cursor-pointer w-fit'>
                    <Cross className='w-5 sm:w-6 xl:w-8' />
                </div>
            </div>
            <div className='w-full sm:w-85 lg:w-70 mx-auto mt-5 sm:mt-20 2xl:mt-32 pb-10'>
                <h1 className='text-honeysuckle font-tobias font-medium text-4xl xl:text-5xl'>Contact me about Banyan Hill</h1>

                {isSubmitted ? (
                    <div className='mt-10 p-6 bg-honeysuckle bg-opacity-20 rounded-lg text-center'>
                        <h2 className='text-honeysuckle text-2xl mb-3'>Thank you for your enquiry!</h2>
                        <p className='text-honeysuckle'>We'll be in touch with you shortly.</p>
                    </div>
                ) : (
                    <form onSubmit={handleSubmit} className='flex flex-col gap-6 sm:gap-10 mt-10'>
                        <div className='flex flex-col sm:flex-row items-center gap-6 sm:gap-10'>
                            <div className='w-full'>
                                <InputField
                                    placeholder='First Name'
                                    variant='honeysuckle'
                                    value={formData.firstName}
                                    onChange={(e) => handleInputChange('firstName', e.target.value)}
                                />
                                {errors.firstName && <span className='text-red-300 text-xs mt-1'>{errors.firstName}</span>}
                            </div>
                            <div className='w-full'>
                                <InputField
                                    placeholder='Last Name'
                                    variant='honeysuckle'
                                    value={formData.lastName}
                                    onChange={(e) => handleInputChange('lastName', e.target.value)}
                                />
                                {errors.lastName && <span className='text-red-300 text-xs mt-1'>{errors.lastName}</span>}
                            </div>
                        </div>
                        <div className='flex flex-col sm:flex-row items-center gap-6 sm:gap-10'>
                            <div className='w-full'>
                                <InputField
                                    placeholder='Email'
                                    variant='honeysuckle'
                                    value={formData.email}
                                    onChange={(e) => handleInputChange('email', e.target.value)}
                                />
                                {errors.email && <span className='text-red-300 text-xs mt-1'>{errors.email}</span>}
                            </div>
                            <div className='w-full'>
                                <InputField
                                    placeholder='Phone'
                                    variant='honeysuckle'
                                    value={formData.phone}
                                    onChange={(e) => handleInputChange('phone', e.target.value)}
                                />
                                {errors.phone && <span className='text-red-300 text-xs mt-1'>{errors.phone}</span>}
                            </div>
                        </div>
                        <div className='flex flex-col sm:flex-row items-center gap-6 sm:gap-10 z-20'>
                            <Select
                                defaultValue={formData.findAbout}
                                options={findOptions}
                                onChange={(value) => handleInputChange('findAbout', value)}
                            />
                            <Select
                                defaultValue={formData.buyTimeline}
                                options={buyOptions}
                                onChange={(value) => handleInputChange('buyTimeline', value)}
                            />
                        </div>
                        <div className='flex flex-col sm:flex-row items-center gap-6 sm:gap-10'>
                            <div className='w-full'>
                                <Select
                                    defaultValue={formData.buyerType}
                                    options={['Buyer Type', ...buyerTypes]}
                                    onChange={(value) => handleInputChange('buyerType', value)}
                                />
                                {errors.buyerType && <span className='text-red-300 text-xs mt-1'>{errors.buyerType}</span>}
                            </div>
                            <div className='w-full'>
                                <InputField
                                    placeholder='Message'
                                    variant='honeysuckle'
                                    value={formData.message}
                                    onChange={(e) => handleInputChange('message', e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="flex gap-6 items-center">
                            <div onClick={() => {
                                setIsChecked(!isChecked);
                                if (errors.terms) {
                                    setErrors(prev => ({ ...prev, terms: '' }));
                                }
                            }}>
                                <Radio variant='honeysuckle' checked={isChecked} onChange={() => setIsChecked(!isChecked)} />
                            </div>
                            <label className="text-xs sm:text-sm font-mori text-honeysuckle">I have read and agree with the <Link className='underline'>Terms & Conditions*</Link></label>
                        </div>
                        {errors.terms && <span className='text-red-300 text-xs mt-1'>{errors.terms}</span>}

                        <div className="mt-6 flex justify-center">
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                onChange={handleRecaptchaChange}
                                theme="dark"
                            />
                        </div>
                        {errors.recaptcha && <span className="text-red-300 text-xs text-center mt-2">{errors.recaptcha}</span>}

                        {errors.general && <div className='text-red-300 text-sm mt-2 text-center'>{errors.general}</div>}
                        <div className='mt-6 sm:mt-10'>
                            <Button
                                title={isSubmitting ? 'Submitting...' : 'Submit'}
                                className='sm:!px-12'
                                onClick={handleSubmit}
                                disabled={isSubmitting}
                            />
                        </div>
                    </form>
                )}
            </div>
        </div>
    )
}

export default EnquireForm