import Banner from "../Components/Banner"
import Button from "../Components/Button";
import { Facts } from "../utils/constants";
import Map from '../assets/images/masterplan.png';
import ByIntrapac from "../Components/ByIntrapac";
import LatestPosts from "../Components/LatestPosts";
import { handleDownload } from "../utils/globalFunctions";

const Masterplan = () => {
    return (
        <>
            {/* Banner */}
            <Banner
                title={'Masterplan'}
                bgImage='bg-masterplan-banner'
            />
            {/* Banner */}

            {/* Content */}
            <section className='px-6 sm:px-10 xl:px-59 pt-8 sm:pt-14 lg:pt-20 pb-8 sm:pb-12 lg:pb-16'>
                <img src={Map} alt="Masterplan" className="max-w-900 m-auto object-contain w-full" />
                <div className="flex flex-col md:flex-row items-center justify-between gap-5 mt-10 mb-5">
                    <Button
                        variant='gableGreen'
                        title='Download Masterplan'
                        className="order-2 md:order-1"
                        onClick={() => handleDownload('https://res.cloudinary.com/intrapac/image/upload/v1634618325/Project/5fbda9b0c41e2c0017347b7a/1634618319004-6dfb0dc0-3096-11ec-a122-6f5ee1481970')}
                    />
                    <p className="font-mori text-gableGreen md:w-3/5 order-1 md:order-2 text-sm sm:text-base">
                        This plan is an illustration for decorative purposes only. It is not to scale and should be used as a guide only. For actual dimensions of lots please refer to plan of subdivision. Subject planning approvals and change.
                    </p>
                </div>

                {/* Fast Facts */}
                <h4 className="font-tobias text-2xl sm:text-3xl lg:text-4xl xl:text-5xl text-gableGreen mt-4 sm:mt-8 lg:mt-12">Fast facts</h4>
                <div className="flex items-center gap-x-6 lg:gap-x-10 mt-3 sm:mt-6 xl:mt-8 w-full flex-wrap">
                    {Facts.map((fact, i) =>
                        <div className="w-full sm:w-45" key={i}>
                            <FastFact fact={fact} className={i <= 1 ? 'border-t' : ''} />
                        </div>
                    )}
                    <div className={`font-mori text-gableGreen text-xs sm:text-sm lg:text-base xl:text-lg mt-6 sm:mt-10`}>
                        *Subject to council approval
                    </div>
                </div>
                {/* Fast Facts */}
            </section>
            {/* Content */}

            {/* By Intrapac */}
            <ByIntrapac />
            {/* By Intrapac */}

            {/* Latest Posts */}
            <LatestPosts />
            {/* Latest Posts */}
        </>
    )
}

const FastFact = ({ fact, className = '' }) => (
    <div className={`font-mori text-gableGreen text-sm sm:text-base lg:text-lg xl:text-xl border-b border-gableGreen py-1 xl:py-2 ${className}`}>
        {fact}
    </div>
)

export default Masterplan