import { Link } from 'react-router-dom';

const ArticleThumbnail = ({ article }) => {
  return (
    <Link to={`/news/${article.id}`} className="block">
      <div className="relative group">
        <div className="overflow-hidden rounded-md shadow-md transition-all duration-300 hover:shadow-lg">
          <div className="aspect-square">
            <img
              src={article.image}
              alt={article.title}
              className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
            />
          </div>
        </div>
        {/* <div className="mt-2">
          <p className="text-white text-xs font-mori line-clamp-1">{article.title}</p>
          <p className="text-honeysuckle/70 text-xs mt-1 font-mori">{article.createdAt}</p>
        </div> */}
      </div>
    </Link>
  );
};

export default ArticleThumbnail;