import Button from './Button';
import Dropdown from './Dropdown';
import Cross from '../assets/svgs/Cross';
import { NavLink } from 'react-router-dom';
import { routes } from '../utils/constants';
import { Fragment } from 'react';
import { useRegistrationForm } from './RegistrationFormProvider';

export const MobileNavbar = ({ toggleNavbar, toggleEnquireForm }) => {
    const { toggleRegistrationForm } = useRegistrationForm();

    return (
        <div className='block lg:hidden px-6 sm:px-10 pt-7 md:pt-38 absolute h-screen top-0 bottom-0 left-0 right-0 bg-gableGreen'>
            <div className='flex items-center'>
                <NavLink to={'/'}>
                    <img src='/logo.png' alt='Logo' className='max-w-40 md:max-w-56 xl:max-w-max' />
                </NavLink>
                <div onClick={toggleNavbar} className='ml-auto cursor-pointer w-fit'>
                    <Cross className='w-5 md:w-8' />
                </div>
            </div>
            <ul className='font-mori text-honeysuckle flex flex-col items-center mt-20'>
                {routes.map((route, i) => (
                    <Fragment key={i}>
                        {route.type === 'select' ?
                            <>
                                <li className='p-3 sm:p-5 ml-4'>
                                    <Dropdown route={route} onClick={toggleNavbar} />
                                </li>
                                <BreakerLine />
                            </>
                            :
                            <>
                                <li className='p-3 sm:p-5 text-base sm:text-lg lg:text-base 2xl:text-lg' onClick={toggleNavbar}>
                                    <NavLink
                                        to={route.path}
                                        className={({ isActive }) =>
                                            [
                                                "hover:underline",
                                                isActive ? "underline" : "",
                                            ].join(" ")
                                        }
                                    >
                                        {route.name}
                                    </NavLink>
                                </li>
                                {i !== routes.length - 1 && <BreakerLine />}
                            </>
                        }
                    </Fragment>
                ))}
                <div className='flex flex-col gap-4 mt-4'>
                    <Button
                        title='Register Now'
                        onClick={() => {
                            toggleRegistrationForm();
                            toggleNavbar();
                        }}
                        className='border-opacity-0.7'
                    />
                    <Button
                        title='Enquire Now'
                        onClick={() => {
                            toggleEnquireForm();
                            toggleNavbar();
                        }}
                        className='border-opacity-0.7'
                    />
                </div>
            </ul>
        </div>
    )
}
const BreakerLine = () => <div className='w-full h-[0.1px] bg-honeysuckle bg-opacity-0.3' />;

export default MobileNavbar