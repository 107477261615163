import { useState, useEffect, useRef } from 'react';
import LeftArrow from "../assets/images/LeftArrow.png";
import RightArrow from "../assets/images/RightArrow.png";
import api from '../services/api';
import ArticleThumbnail from './ArticleThumbnail';

export const LatestPosts = () => {
    const [latestNews, setLatestNews] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const slidesContainerRef = useRef(null);

    useEffect(() => {
        fetchLatestNews();
    }, []);

    const fetchLatestNews = async () => {
        try {
            setLoading(true);
            const data = await api.getNews();
            // Sort by date and take only the 10 most recent articles
            const sortedData = data
                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                .slice(0, 10);
            setLatestNews(sortedData);
            setLoading(false);
        } catch (err) {
            setError('Failed to load latest news');
            setLoading(false);
        }
    };

    // Get maximum number of items that can be displayed based on screen size
    const getVisibleItemsPerPage = () => {
        // Maximum of 5 items per row/page regardless of screen size
        return 5;
    };

    const handlePrevSlide = () => {
        const itemsPerPage = getVisibleItemsPerPage();
        const newIndex = Math.max(0, currentIndex - itemsPerPage);
        setCurrentIndex(newIndex);
    };

    const handleNextSlide = () => {
        const itemsPerPage = getVisibleItemsPerPage();
        const newIndex = currentIndex + itemsPerPage;

        if (newIndex < latestNews.length) {
            setCurrentIndex(newIndex);
        } else {
            // Cycle to the beginning
            setCurrentIndex(0);
        }
    };

    // Determine which items to show based on current index
    const getVisibleItems = () => {
        if (latestNews.length === 0) return [];

        const itemsPerPage = getVisibleItemsPerPage();
        const startIdx = currentIndex;
        const endIdx = Math.min(startIdx + itemsPerPage, latestNews.length);
        return latestNews.slice(startIdx, endIdx);
    };

    const visibleItems = getVisibleItems();
    const hasMoreNext = currentIndex + getVisibleItemsPerPage() < latestNews.length;
    const hasMorePrev = currentIndex > 0;

    if (loading && latestNews.length === 0) {
        return (
            <section className="bg-pacifika px-6 sm:px-10 xl:px-59 py-8 sm:py-10 lg:py-20">
                <h4 className="font-tobias text-honeysuckle text-2xl sm:text-3xl lg:text-4xl">The latest</h4>
                <div className="flex justify-center mt-8">
                    <p className="text-white">Loading latest posts...</p>
                </div>
            </section>
        );
    }

    if (error && latestNews.length === 0) {
        return (
            <section className="bg-pacifika px-6 sm:px-10 xl:px-59 py-8 sm:py-10 lg:py-20">
                <h4 className="font-tobias text-honeysuckle text-2xl sm:text-3xl lg:text-4xl">The latest</h4>
                <div className="flex justify-center mt-8">
                    <p className="text-white">{error}</p>
                </div>
            </section>
        );
    }

    return (
        <section className="bg-pacifika px-6 sm:px-10 xl:px-59 py-8 sm:py-10 lg:py-20">
            <h4 className="font-tobias text-honeysuckle text-2xl sm:text-3xl lg:text-4xl mb-6 sm:mb-8">The latest</h4>

            <div className="relative">
                {/* Previous button - positioned at the left */}
                <button
                    className={`absolute left-0 top-1/2 -translate-y-1/2 -translate-x-4 sm:-translate-x-6 z-10 bg-pacifika/90 p-2 rounded-full ${!hasMorePrev ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer hover:opacity-80'}`}
                    onClick={handlePrevSlide}
                    disabled={!hasMorePrev}
                    aria-label="Previous articles"
                >
                    <img src={LeftArrow} alt='Previous' />
                </button>

                {/* Grid of articles */}
                <div
                    ref={slidesContainerRef}
                    className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 sm:gap-6 lg:gap-7 px-6 sm:px-10 xl:px-59"
                >
                    {visibleItems.map((article) => (
                        <div key={article.id} className="w-full">
                            <ArticleThumbnail article={article} />
                        </div>
                    ))}
                </div>

                {/* Next button - positioned at the right */}
                <button
                    className={`absolute right-0 top-1/2 -translate-y-1/2 translate-x-4 sm:translate-x-6 z-10 bg-pacifika/90 p-2 rounded-full ${!hasMoreNext ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer hover:opacity-80'}`}
                    onClick={handleNextSlide}
                    disabled={!hasMoreNext}
                    aria-label="Next articles"
                >
                    <img src={RightArrow} alt='Next' />
                </button>
            </div>
        </section>
    );
}

export default LatestPosts;