const OurPolicies = () => {
    return (
        <section className='px-6 sm:px-10 xl:px-59 pb-12 md:pb-16 xl:pb-24 2xl:pb-28 mt-28 sm:mt-40'>
            <div className='w-full sm:w-1/2'>
                <h1 className='font-tobias text-2xl sm:text-3xl lg:text-4xl xl:text-40 2xl:text-5xl text-gableGreen'>Our policies</h1>
                <div className="mt-8">
                    <h2 className='font-mori text-base sm:text-lg lg:text-xl xl:text-2xl font-medium text-gableGreen'>Privacy collection statement</h2>
                    <h3 className='font-mori text-sm sm:text-base lg:text-lg font-medium text-gableGreen mt-4'>Intrapac Group</h3>
                    <div className={`${text} sm:mt-3 lg:mt-5 border-l-4 border-gableGreen pl-4 italic`}>
                        <p>Unless expressly stated otherwise in this privacy collection statement, a reference to &apos;we&apos;, &apos;us&apos; and &apos;our&apos; means each of Intrapac Property Pty Ltd (ACN 107 291 805), Fendbrook Pty Ltd (ACN 122 938 685), Hadenbrook Pty Ltd (ACN 122 647 330), Intrapac Coops Pty Ltd (ACN 125 824 026), Intrapac Noonamah Pty Ltd (ACN 144 929 519), Intrapac Queensland Pty Ltd (ACN 120 899 872), Intrapac Victoria Pty Ltd (ACN 122 026 420), Intrapac Darwin Pty Ltd (ACN 627 097 803), Intrapac Darwin Developments Pty Ltd (ACN 138 204 387), Intrapac Brisbane Pty Ltd (ACN 605 530 952), Intrapac Ellarook Pty Ltd (ACN 128 718 025), Intrapac Skennars Head Pty Ltd (ACN 609 488 780), Somerfield Supplies Pty Ltd (ACN 609 489 321), Intrapac Communities Pty Ltd (ACN 628 409 727), New Urban Projects Pty Ltd (ACN 111 464 227) and Intrapac Terranora Pty Ltd (ACN 664 363 633), and each of their related bodies corporate from time to time (collectively the &quot;Intrapac Group&quot;).</p>
                    </div>
                    <p className={`${text} mt-4`}>We are committed to protecting the privacy of your personal information. You can access our full privacy policy at <a href="https://intrapac.com.au/privacy-policy/" className="text-gableGreen underline">https://intrapac.com.au/privacy-policy/</a>.</p>
                    <p className={`${text} mt-4`}>During the course of our operations, we collect personal information. We generally collect personal information from the individual concerned. We may also collect personal information from third parties including government bodies (such as regulatory authorities, relevant departments, etc), through referrals from individuals or other entities, professional advisers (including legal advisers and conveyancers), banks and financial institutions, paid search providers, third party platforms or websites that you may use (such as Facebook, realestate.com.au and OpenLot), our business partners (including our joint venture partners), our employees, contractors and agents, the employees, contractors and agents of third parties and through marketing and business development events.</p>
                    <p className={`${text} mt-4`}>The primary purpose for which we collect, hold, use and disclose personal information varies depending on the individual that we are collecting the information from, but is generally as follows:</p>
                    <p className={`${text} mt-2`}>a. in the case of a current or prospective client or buyer, to provide our goods and services or to otherwise engage with you in the course of our business;</p>
                    <p className={`${text} mt-2`}>b. in the case of other individuals that contact us (including users of our websites), to assist us to respond to your enquiries or complaints or to otherwise engage with you in the course of our business;</p>
                    <p className={`${text} mt-2`}>c. in the case of a current contractor, consultant or agent, to assist us in providing our goods and services or to otherwise engage with you in the course of our business; and</p>
                    <p className={`${text} mt-2`}>d. in the case of a prospective contractor, consultant, agent or employee, to assess your suitability for employment or engagement.</p>
                    <p className={`${text} mt-4`}>Any member of the Intrapac Group may also disclose and collect personal information from and to other members of the Intrapac Group.</p>
                    <p className={`${text} mt-4`}>We may also collect, hold, use and disclose personal information for secondary purposes that we expect to be within your reasonable expectations and related to the primary purpose of collection.</p>
                    <p className={`${text} mt-4`}>If you do not provide us with the requested information, we may not be able to provide you with our goods and or services or otherwise engage with you. In limited circumstances, it may be possible for you to use a pseudonym or remain anonymous when dealing with us. If you wish to use a pseudonym or remain anonymous you should notify us when making first enquiries or providing initial instructions.</p>
                    <p className={`${text} mt-4`}>We may disclose your personal information to other entities, including other APP entities as defined in the <em>Privacy Act 1988</em> (Cth), such as government bodies (such as regulatory authorities, relevant departments, etc), our external auditors and other professional advisers, our contractors, consultants, employees and agents, our paid service providers, our business partners (including our joint venture partners), law enforcement agencies, if you are a current or prospective buyer then your neighbours for the purposes of finalising fencing arrangements and us otherwise providing our goods and services, parties considering the acquisition of the whole or a part of our business and other third parties with whom we have a commercial relationship.</p>
                    <p className={`${text} mt-4`}>We will only disclose personal information outside of Australia if you expressly request us to do so. Otherwise, we will not disclose your personal information to entities outside of Australia.</p>
                    <p className={`${text} mt-4`}>Our detailed privacy policy at <a href="https://intrapac.com.au/privacy-policy/" className="text-gableGreen underline">https://intrapac.com.au/privacy-policy/</a> includes further information on how you can access and seek correction of your personal information and how you can complain about a breach of your privacy. The policy also contains information about how we will deal with a complaint.</p>
                    <p className={`${text} mt-4`}>If you have any questions about our information handling practice, please contact us at the following address:</p>
                    <p className={`${text} mt-2`}>Privacy Officer: Sally Steinkrug</p>
                    <p className={`${text} mt-2`}>Postal address: Level 6, 580 St Kilda Road Melbourne VIC 3004</p>
                    <p className={`${text} mt-2`}>Telephone number: +61 3 9207 8000</p>
                    <p className={`${text} mt-2`}>Email address: <a href="mailto:info@intrapac.com.au" className="text-gableGreen underline">info@intrapac.com.au</a></p>
                </div>

                <div className="mt-12">
                    <h2 className='font-mori text-base sm:text-lg lg:text-xl xl:text-2xl font-medium text-gableGreen'>Privacy policy</h2>
                    <h3 className='font-mori text-sm sm:text-base lg:text-lg font-medium text-gableGreen mt-4'>Intrapac Group</h3>

                    <h4 className='font-mori text-sm sm:text-base font-medium text-gableGreen mt-4'>1 Introduction</h4>
                    <p className={`${text} mt-2`}>1.1 Unless expressly stated otherwise in this privacy policy, a reference to &apos;we&apos;, &apos;us&apos; and &apos;our&apos; means each of Intrapac Property Pty Ltd (ACN 107 291 805), Fendbrook Proprietary Limited (ACN 122 938 685), Hadenbrook Proprietary Limited (ACN 122 647 330), Intrapac Coops Pty Ltd (ACN 125 824 026), Intrapac Noonamah Pty Ltd (ACN 144 929 519), Intrapac Queensland Pty. Ltd. (ACN 120 899 872), Intrapac Victoria Pty Ltd (ACN 122 026 420), Intrapac Darwin Pty Ltd (ACN 627 097 803), Intrapac Darwin Developments Pty Ltd (ACN 138 204 387), Intrapac Brisbane Pty Ltd (ACN 605 530 952), Intrapac Ellarook Pty Ltd (ACN 128 718 025), Intrapac Skennars Head Pty Ltd (ACN 609 488 780), Somerfield Supplies Pty Ltd (ACN 609 489 321), Intrapac Communities Pty Ltd (ACN 628 409 727), New Urban Projects Pty Ltd (ACN 111 464 227) and Intrapac Terranora Pty Ltd (ACN 664 363 633), and each of their related bodies corporate from time to time (collectively the &quot;Intrapac Group&quot;).</p>
                    <p className={`${text} mt-2`}>1.2 In the course of doing business in Australia, there are circumstances where we collect personal information. This privacy policy has been developed to ensure that such information is handled appropriately.</p>
                    <p className={`${text} mt-2`}>1.3 We are committed to complying with the Privacy Act 1988 (Cth) (Privacy Act) in relation to all personal information we collect. Our commitment is demonstrated in this policy. The Privacy Act incorporates the Australian Privacy Principles (APPs). The APPs set out the way in which personal information must be treated.</p>
                    <p className={`${text} mt-2`}>1.4 By providing your details, you consent to us collecting, holding, using and disclosing your personal information in accordance with this policy.</p>

                    <h5 className={`font-mori text-sm font-medium text-gableGreen mt-3`}>Who does the privacy policy apply to?</h5>
                    <p className={`${text} mt-2`}>1.5 This policy applies to any person for whom we currently hold, or may in the future collect, personal information. Broadly, we may collect personal information relating to our prospective employees, our current and prospective buyers, clients, contractors, consultants and agents and other individuals that contact the Intrapac Group with enquiries.</p>
                    <p className={`${text} mt-2`}>1.6 This policy does not apply to acts and practices in relation to &apos;employee records&apos; of our current or former employees, as these are exempt from the Privacy Act.</p>

                    <h5 className={`font-mori text-sm font-medium text-gableGreen mt-3`}>What information does the privacy policy apply to?</h5>
                    <p className={`${text} mt-2`}>1.7 This policy applies to personal information. In broad terms, &apos;personal information&apos; is information or opinions relating to a particular individual who can be identified.</p>
                    <p className={`${text} mt-2`}>1.8 Information is not personal information where the information cannot be linked to an identifiable individual.</p>

                    <h5 className={`font-mori text-sm font-medium text-gableGreen mt-3`}>Anonymity and pseudonymity</h5>
                    <p className={`${text} mt-2`}>1.9 We will take reasonable steps to destroy or permanently de-identify personal information if that information is no longer needed for the purposes for which we are authorised to use it.</p>
                    <p className={`${text} mt-2`}>1.10 In limited circumstances, it may be possible for you to use a pseudonym or remain anonymous when dealing with us. If you wish to use a pseudonym or remain anonymous you should notify us when making first enquiries. We will use our best endeavours to deal with your request, subject to our ability to interact with you without having your personal information.</p>
                    <p className={`${text} mt-2`}>1.11 You do not have to provide your personal information to us but if you do not provide the information requested by us, we may not be able to provide you with our goods or services or otherwise engage with you.</p>

                    <h4 className='font-mori text-sm sm:text-base font-medium text-gableGreen mt-4'>2 What kinds of information do we collect and hold?</h4>
                    <h5 className={`font-mori text-sm font-medium text-gableGreen mt-3`}>Personal information</h5>
                    <p className={`${text} mt-2`}>2.1 The personal information we will collect differs depending on the nature of our interaction with you, although such information may include:</p>
                    <p className={`${text} mt-2`}>(a) sensitive information (see paragraph 2.2);</p>
                    <p className={`${text} mt-2`}>(b) contact and identification information, including full name, contact details and emergency contact details;</p>
                    <p className={`${text} mt-2`}>(c) age, such as date and place of birth details;</p>
                    <p className={`${text} mt-2`}>(d) marital status;</p>
                    <p className={`${text} mt-2`}>(e) financial information, including household income;</p>
                    <p className={`${text} mt-2`}>(f) information relating to your immediate family and relatives;</p>
                    <p className={`${text} mt-2`}>(g) current and historical employment arrangements;</p>
                    <p className={`${text} mt-2`}>(h) tax file numbers;</p>
                    <p className={`${text} mt-2`}>(i) banking details;</p>
                    <p className={`${text} mt-2`}>(j) government related identifiers, such as a driver licence number;</p>
                    <p className={`${text} mt-2`}>(k) details of any enquiries you make; and</p>
                    <p className={`${text} mt-2`}>(l) any other personal information required to provide goods or services to you or otherwise engage you to assist us to provide our goods and services.</p>

                    <h5 className={`font-mori text-sm font-medium text-gableGreen mt-3`}>Sensitive information</h5>
                    <p className={`${text} mt-2`}>2.2 &apos;Sensitive information&apos; is a subset of personal information under the Privacy Act and includes personal information that may have serious ramifications for the individual concerned if used inappropriately.</p>
                    <p className={`${text} mt-2`}>2.3 The sensitive information we will collect differs depending on the nature of our interaction with you, although such information may include:</p>
                    <p className={`${text} mt-2`}>(a) racial or ethnic origin;</p>
                    <p className={`${text} mt-2`}>(b) criminal records;</p>
                    <p className={`${text} mt-2`}>(c) sexual orientation or practices;</p>
                    <p className={`${text} mt-2`}>(d) religious beliefs or affiliations; and</p>
                    <p className={`${text} mt-2`}>(e) membership of professional or trade associations.</p>
                    <p className={`${text} mt-2`}>2.4 We will not collect sensitive information without the consent of the individual to whom the information relates unless permitted under the Privacy Act.</p>

                    <h5 className={`font-mori text-sm font-medium text-gableGreen mt-3`}>Web-generated information</h5>
                    <p className={`${text} mt-2`}>2.5 Our websites (including, www.intrapac.com.au, www.mywhiterock.com.au, www.aureus.com.au, www.banyanhill.com.au, www.mirawood.com.au, www.ellarook.com.au, www.avantth.com.au, www.kinley.com.au, www.seshsportshub.com.au, www.lloydcreek.com.au, www.149mckimmies.com.au, www.sixthavenue.com.au, www.quay2.com.au, www.kingscoast.com.au and other websites owned or operated by the Intrapac Group from time to time) may also automatically collect hardware and software information about your computer or device, including:</p>
                    <p className={`${text} mt-2`}>(a) your IP address;</p>
                    <p className={`${text} mt-2`}>(b) your browser type;</p>
                    <p className={`${text} mt-2`}>(c) the types of devices you are using to access our websites;</p>
                    <p className={`${text} mt-2`}>(d) the language and operating system of the device being used;</p>
                    <p className={`${text} mt-2`}>(e) domain names, access times and referring website addresses; and</p>
                    <p className={`${text} mt-2`}>(f) page clicks, time spent and other automatically collected meta-data.</p>
                    <p className={`${text} mt-2`}>2.6 Our websites may also collect usage information from users. Broadly, this information may relate to how you use and navigate the website, including:</p>
                    <p className={`${text} mt-2`}>(a) information about pages, content or advertisements you have browsed or clicked on;</p>
                    <p className={`${text} mt-2`}>(b) any content, information or material you disclose to us;</p>
                    <p className={`${text} mt-2`}>(c) the location from which you have come to the site and the pages you have visited; and</p>
                    <p className={`${text} mt-2`}>(d) information about the features you have used on our websites.</p>

                    <h4 className='font-mori text-sm sm:text-base font-medium text-gableGreen mt-4'>3 How and when do we collect personal information?</h4>
                    <p className={`${text} mt-2`}>3.1 We usually collect personal information directly from the individual concerned, for example, via emails, telephone calls, electronic or physical forms, our website and social media pages, invoices, physical or virtual meetings and questionnaires.</p>
                    <p className={`${text} mt-2`}>3.2 We may also collect personal information from third parties such as:</p>
                    <p className={`${text} mt-2`}>(a) government bodies (such as regulatory authorities, relevant departments, etc.);</p>
                    <p className={`${text} mt-2`}>(b) through referrals from individuals or other entities;</p>
                    <p className={`${text} mt-2`}>(c) professional advisers, including legal advisers and conveyancers;</p>
                    <p className={`${text} mt-2`}>(d) banks and financial institutions;</p>
                    <p className={`${text} mt-2`}>(e) paid search providers;</p>
                    <p className={`${text} mt-2`}>(f) third party platforms or websites that you may use, such as Facebook, realestate.com.au and OpenLot,</p>
                    <p className={`${text} mt-2`}>(g) our business partners, including our joint venture partners;</p>
                    <p className={`${text} mt-2`}>(h) our employees, contractors and agents;</p>
                    <p className={`${text} mt-2`}>(i) the employees, contractors and agents of third parties; and</p>
                    <p className={`${text} mt-2`}>(j) through marketing and business development events.</p>
                    <p className={`${text} mt-2`}>3.3 Any member of the Intrapac Group may also collect personal information from other members of the Intrapac Group.</p>

                    <h4 className='font-mori text-sm sm:text-base font-medium text-gableGreen mt-4'>4 How do we hold personal information?</h4>
                    <p className={`${text} mt-2`}>4.1 Our usual approach to holding personal information is to store it:</p>
                    <p className={`${text} mt-2`}>(a) physically, in paper files stored securely at our premises; and</p>
                    <p className={`${text} mt-2`}>(b) electronically in computer systems, applications, databases and cloud servers, either operated by us or our third-party service providers.</p>
                    <p className={`${text} mt-2`}>4.2 We implement and maintain processes and security measures to protect personal information which we hold from misuse, interference or loss and from unauthorised access, modification or disclosure. Some of these processes and systems include:</p>
                    <p className={`${text} mt-2`}>(a) using secure servers to store personal information;</p>
                    <p className={`${text} mt-2`}>(b) using unique usernames, passwords and other protections on systems that can access personal information; and</p>
                    <p className={`${text} mt-2`}>(c) holding certain sensitive documents securely.</p>

                    <h4 className='font-mori text-sm sm:text-base font-medium text-gableGreen mt-4'>5 Why do we collect, hold, use or disclose personal information?</h4>
                    <p className={`${text} mt-2`}>5.1 We take reasonable steps to hold, use and disclose personal information for the primary purpose for which we collect it. The primary purpose for which information is collected varies depending on the individual that we are collecting from, but is generally as follows:</p>
                    <p className={`${text} mt-2`}>(a) in the case of a current or prospective client or buyer, to provide our goods and services or to otherwise engage with you in the course of our business;</p>
                    <p className={`${text} mt-2`}>(b) in the case of other individuals that contact us (including users of our websites), to assist us to respond to your enquiries or complaints or to otherwise engage with you in the course of our business;</p>
                    <p className={`${text} mt-2`}>(c) in the case of a current contractor, consultant or agent, to assist us in providing our goods and services or to otherwise engage with you in the course of our business; and</p>
                    <p className={`${text} mt-2`}>(d) in the case of a prospective contractor, consultant, agent or employee, to assess your suitability for employment or engagement.</p>
                    <p className={`${text} mt-2`}>5.2 We may also collect, hold, use and disclose personal information for secondary purposes that are within your reasonable expectations and that are related to the primary purpose of collection.</p>
                    <p className={`${text} mt-2`}>5.3 From time to time, we may disclose personal information to third parties such as:</p>
                    <p className={`${text} mt-2`}>(a) government bodies (such as regulatory authorities, relevant departments, etc.);</p>
                    <p className={`${text} mt-2`}>(b) our external auditors and other professional advisers;</p>
                    <p className={`${text} mt-2`}>(c) our contractors, consultants, employees and agents;</p>
                    <p className={`${text} mt-2`}>(d) our paid service providers;</p>
                    <p className={`${text} mt-2`}>(e) our business partners, including our joint venture partners;</p>
                    <p className={`${text} mt-2`}>(f) law enforcement agencies;</p>
                    <p className={`${text} mt-2`}>(g) if you are a current or prospective buyer, your neighbours for the purposes of finalising fencing arrangements and us otherwise providing our goods and services;</p>
                    <p className={`${text} mt-2`}>(h) parties considering the acquisition of the whole or a part of our business; and</p>
                    <p className={`${text} mt-2`}>(i) other third parties with whom we have a commercial relationship.</p>
                    <p className={`${text} mt-2`}>5.4 Any member of the Intrapac Group may disclose personal information to other members of the Intrapac Group.</p>
                    <p className={`${text} mt-2`}>5.5 We will only disclose personal information to third parties or other members of the Intrapac Group if:</p>
                    <p className={`${text} mt-2`}>(a) we are required or authorised by law to do so;</p>
                    <p className={`${text} mt-2`}>(b) we have received express consent to the disclosure, or consent may be reasonably inferred from the circumstances; or</p>
                    <p className={`${text} mt-2`}>(c) we are otherwise permitted to disclose the information under the Privacy Act.</p>

                    <h4 className='font-mori text-sm sm:text-base font-medium text-gableGreen mt-4'>6 Will we disclose personal information outside Australia?</h4>
                    <p className={`${text} mt-2`}>6.1 We will only disclose personal information outside of Australia if you expressly request us to do so. Otherwise, we will not disclose your personal information to entities outside of Australia, including by storing personal information with data centres located in other countries).</p>
                    <p className={`${text} mt-2`}>6.2 If you expressly request us to disclose your personal information to parties located overseas (or which have data centres located in other countries), we take reasonable steps to ensure that those parties will handle the personal information in accordance with the Australian Privacy Principles. We are not required to take such steps if we believe that the overseas recipient is already subject to a law that has the effect of protecting personal information in a substantially similar way to the relevant law in Australia, or with your consent.</p>

                    <h4 className='font-mori text-sm sm:text-base font-medium text-gableGreen mt-4'>7 How do you make complaints and access and correct your personal information?</h4>
                    <p className={`${text} mt-2`}>7.1 It is important that the information we hold about you is up-to-date. If you believe that any information we hold about you is incorrect, please contact us so we can make the necessary change(s).</p>
                    <p className={`${text} mt-2`}>7.2 If the personal information we hold is not accurate, complete and up-to-date, we will take reasonable steps to correct it so that it is accurate, complete and up-to-date, where it is appropriate to do so.</p>

                    <h5 className={`font-mori text-sm font-medium text-gableGreen mt-3`}>Access to information and correcting personal information</h5>
                    <p className={`${text} mt-2`}>7.3 You may request access to the personal information held by us or ask us for your personal information to be corrected by using the contact details in this section.</p>
                    <p className={`${text} mt-2`}>7.4 We will grant you access to your personal information as soon as possible, subject to the request circumstances.</p>
                    <p className={`${text} mt-2`}>7.5 In keeping with our commitment to protect the privacy of personal information, we may not disclose personal information to you without proof of identity.</p>
                    <p className={`${text} mt-2`}>7.6 We may deny access to personal information if:</p>
                    <p className={`${text} mt-2`}>(a) the request is unreasonable;</p>
                    <p className={`${text} mt-2`}>(b) providing access would have an unreasonable impact on the privacy of another person;</p>
                    <p className={`${text} mt-2`}>(c) providing access would pose a serious and imminent threat to the life or health of any person;</p>
                    <p className={`${text} mt-2`}>(d) providing access would compromise our professional obligations; or</p>
                    <p className={`${text} mt-2`}>(e) there are other legal grounds to deny the request.</p>
                    <p className={`${text} mt-2`}>7.7 We may charge a fee for reasonable costs incurred in responding to an access request. The fee (if any) will be disclosed prior to it being levied.</p>

                    <h5 className={`font-mori text-sm font-medium text-gableGreen mt-3`}>Complaints</h5>
                    <p className={`${text} mt-2`}>7.8 If you wish to complain about an interference with your privacy, then you must follow the following process.</p>
                    <p className={`${text} mt-2`}>(a) The complaint must be firstly made to us in writing, using the contact details in this section. We will have a reasonable time to respond to the complaint.</p>
                    <p className={`${text} mt-2`}>(b) If the privacy issue cannot be resolved, you may take your complaint to the Office of the Australian Information Commissioner.</p>

                    <h5 className={`font-mori text-sm font-medium text-gableGreen mt-3`}>Who to contact</h5>
                    <p className={`${text} mt-2`}>7.9 A person may make a complaint or request to access or correct personal information about them held by us. Such a request must be made in writing to the following address:</p>
                    <p className={`${text} mt-2`}>Privacy Officer: Sally Steinkrug</p>
                    <p className={`${text} mt-2`}>Postal address: Level 6, 580 St Kilda Road Melbourne VIC 3004</p>
                    <p className={`${text} mt-2`}>Telephone number: +61 3 9207 8000</p>
                    <p className={`${text} mt-2`}>Email address: <a href="mailto:info@intrapac.com.au" className="text-gableGreen underline">info@intrapac.com.au</a></p>

                    <h4 className='font-mori text-sm sm:text-base font-medium text-gableGreen mt-4'>8 Changes to the policy</h4>
                    <p className={`${text} mt-2`}>8.1 We may update, modify or remove this policy at any time without prior notice. Any changes to the privacy policy will be published on our website(s) or otherwise notified to you.</p>
                    <p className={`${text} mt-2`}>8.2 This policy is effective November 2024. If you have any comments on the policy, please contact the privacy officer with the contact details in section 7 of this policy.</p>
                </div>
            </div>
        </section>
    )
}

const text = 'font-mori text-xs lg:text-sm xl:text-base text-gableGreen mt-2';

export default OurPolicies