import React, { useState } from 'react';
import ArrowDown from '../assets/svgs/ArrowDown';

export const Select = ({ options = [], defaultValue = '', color = 'honeysuckle', onChange = () => {} }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [value, setValue] = useState(defaultValue);

    const openDropdown = () => setIsOpen(true);
    const closeDropdown = () => setIsOpen(false);

    const handleSelect = (item) => {
        setValue(item);
        onChange(item);
        closeDropdown();
    };

    return (
        <div className="relative w-full" onMouseLeave={closeDropdown}>
            <div onClick={openDropdown} className={`border-b-2 pb-1 text-${color} border-${color} text-xs sm:text-sm xl:text-base flex items-center justify-between gap-3 lg:gap-1 xl:gap-2 cursor-pointer`}>
                <div>{value}</div>
                <ArrowDown className={`text-${color} opacity-0.5`} />
            </div>
            {isOpen &&
                <div
                    className={`lg:absolute origin-top-right focus:outline-none border w-full rounded-lg border-${color} bg-gableGreen`}
                    role="menu"
                >
                    {options.map((item, i) => (
                        <div
                            key={i}
                            onClick={() => handleSelect(item)}
                            className={`text-${color} cursor-pointer text-xs sm:text-sm xl:text-base block px-4 py-1 xl:py-2 hover:bg-lightGrey rounded-lg`}
                        >
                            {item}
                        </div>
                    ))}
                </div>
            }
        </div>
    );
}

export default Select