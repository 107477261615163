import ScrollAnimation from "react-animate-on-scroll"

export const Card = ({ image, heading, text, showImageFirst = true, children }) => {
    return (
        <div className='flex flex-col lg:flex-row gap-5 lg:gap-10'>
            <ScrollAnimation animateIn="slideInLeft" className={`w-full lg:w-1/2 order-1 ${showImageFirst ? 'lg:order-1' : 'lg:order-2'}`}>
                <img src={image} alt="Cover_Photo" className='max-w-900 w-full max-h-400 lg:max-h-max object-cover' />
            </ScrollAnimation>
            <ScrollAnimation animateIn="slideInRight" className={`w-full lg:w-1/2 order-2 ${showImageFirst ? 'lg:order-2' : 'lg:order-1'}`}>
                <h4 className='font-tobias text-2xl sm:text-3xl xl:text-4xl 2xl:text-5xl text-gableGreen'>{heading}</h4>
                <p className='font-mori text-sm sm:text-base 2xl:text-xl leading-5 2xl:leading-6 mt-2 sm:mt-4 lg:mt-6'>
                    {text}
                </p>
                {children}
            </ScrollAnimation>
        </div>
    )
}

export default Card