import React from 'react';

const BuilderLogo = ({ logo, alt, isSelected, onClick }) => {
    return (
        <div
            className={`w-40 h-40 rounded-full flex items-center justify-center bg-white p-4 shadow-md border overflow-hidden cursor-pointer transition-all duration-300 ${isSelected ? 'border-turquoise border-4 shadow-lg shadow-turquoise/30' : 'border-gray-100'}`}
            onClick={onClick}
        >
            <img src={logo} alt={alt} className="max-w-full h-auto" />
        </div>
    );
};

export default BuilderLogo;