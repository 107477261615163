import ScrollAnimation from "react-animate-on-scroll";

export const ProgressCard = ({ steps = [] }) => {
    return (
        <ScrollAnimation animateIn="fadeIn" className='bg-satinLinen px-6 py-6 lg:px-10 sm:p-10'>
            <h4 className='text-gableGreen font-tobias text-2xl sm:text-3xl xl:text-4xl'>Stage XX – XX Release</h4>
            <div className='flex flex-col sm:flex-row justify-between gap-2 sm:gap-10 mt-4 sm:mt-8'>
                <div className='w-full sm:w-1/3'>
                    <p className={boldText}>Construction started</p>
                    <p className={regularText}>[Month] [Year]</p>
                </div>
                <div className='w-full sm:w-1/3'>
                    <p className={boldText}>Estimated title due</p>
                    <p className={regularText}>[Month] [Year]</p>
                </div>
                <div className='w-full sm:w-1/3'>
                    <p className={boldText}>Estimated settlement due</p>
                    <p className={regularText}>[Month] [Year]</p>
                </div>
            </div>

            <div className="hidden lg:block mt-24">
                <Stepper steps={steps} />
            </div>
            <div className="block lg:hidden mt-16 sm:mt-24">
                <Stepper steps={steps.filter(x => x.status)} />
                <div className='mt-20 sm:mt-28'>
                    <Stepper steps={steps.filter(x => !x.status)} />
                </div>
            </div>
            <div className='flex items-center justify-between gap-5 mt-16 sm:mt-24 lg:mt-32'>
                <p className={boldText}>Civil – 50% Completed</p>
                <p className={boldText}>Registration – 0% Completed</p>
            </div>
        </ScrollAnimation>
    )
}

const Stepper = ({ steps }) => (
    <div className='flex items-center'>
        {steps.map((step, i) => (
            <div className="flex items-center w-full" key={i}>
                <div className={`w-full h-2 sm:h-3 xl:h-4 ${i === 0 ? 'rounded-s-lg' : ''} bg-${step.color ?? 'white'}`} />
                <div className='z-10 relative'>
                    {step.status === 'in-progress' ?
                        <>
                            {/* Tooltip */}
                            <div className="absolute -left-8 xl:-left-7 -top-9 lg:-top-11 flex flex-col items-center w-max">
                                <div className="font-mori bg-white text-sycamore text-10 sm:text-xs font-medium py-1 lg:py-2 px-3 rounded-2xl shadow-lg">
                                    In Progress
                                </div>
                                <div className="w-0 h-0 border-l-8 border-l-transparent border-r-8 border-r-transparent border-t-8 border-t-white mt-[-1px]" />
                            </div>
                            {/* Tooltip */}

                            <div className={`z-10 w-4 sm:w-6 xl:w-8 h-4 sm:h-6 xl:h-8 shrink-0 mx-[-2px] bg-sycamore border-4 border-gableGreen p-1.5 flex items-center justify-center rounded-full`} />
                        </>
                        :
                        <div className={`z-10 w-4 sm:w-6 xl:w-8 h-4 sm:h-6 xl:h-8 shrink-0 mx-[-2px] bg-${step.status === 'completed' ? 'gableGreen' : 'white'} p-1.5 flex items-center justify-center rounded-full`} />
                    }
                    <div className={`${step.status === 'in-progress' ? 'block' : 'hidden'} leading-3 lg:block -left-6 sm:-left-6 xl:-left-8 min-w-16 xl:min-w-24 text-center absolute text-10 xl:text-sm mt-2 text-gableGreen`}>{step.label}</div>
                </div>
                {i !== steps.length - 1 && <div className={`w-full h-2 sm:h-3 xl:h-4 bg-${(steps[i + 1] ? steps[i + 1]?.color : step.color) ?? 'white'}`} />}
            </div>
        ))}
    </div>
)

const regularText = 'text-gableGreen text-xs sm:text-sm xl:text-base font-mori';
const boldText = `${regularText} font-medium`;

export default ProgressCard