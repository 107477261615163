import React from 'react';
import backgroundImage from '../../assets/images/dp-displayvillagemap.jpg';

const DisplayVillageMap = () => {
    return (
        <div
            className="h-[770px] w-full flex text-center py-16 px-4 bg-cover bg-center bg-no-repeat relative justify-center items-center"
            style={{
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center'
            }}
        >
            {/* Semi-transparent overlay for better text readability */}
            {/* <div className="absolute inset-0 bg-black bg-opacity-50"></div> */}

            {/* Content positioned above the overlay */}
            <div className="relative z-10">
                <h2 className="font-tobias font-bold text-gableGreen text-2xl sm:text-3xl lg:text-4xl mb-6">
                    View some of Australia's best builders at Banyan Hill, Ballina
                </h2>
                <div className="w-16 h-0.5 bg-black mx-auto mb-10"></div>
                <div className="mt-8">
                    <a
                        href={require('../../assets/pdfs/14352_20230116_CR05_Display-Village-Flyer.pdf')}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="bg-gableGreen hover:bg-gableGreen-dark text-white font-bold py-3 px-6 rounded-full transition duration-300 inline-block"
                    >
                        DOWNLOAD OUR DISPLAY VILLAGE MAP
                    </a>
                </div>
            </div>
        </div>
    );
};

export default DisplayVillageMap;