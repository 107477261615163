import ScrollAnimation from 'react-animate-on-scroll';
import ConstructionSite from '../assets/images/construction_1.jpg';
import { stage1Steps, stage2Steps } from '../utils/constants';
import {
    Banner,
    LatestPosts,
    ProgressCard,
    PageIntroSection
} from '../Components';

const Progress = () => {
    return (
        <>
            {/* Banner */}
            <Banner
                title={'Construction updates'}
                bgImage='bg-progress-banner'
            />
            {/* Banner */}

            {/* Content */}
            <PageIntroSection
                text="At a glance, see how your new Banyan Hill community is progressing. We will highlight where you're at in the process and identify what next to expect. Visit as often as you like to follow every exciting development."
                className="px-0 sm:px-10 xl:px-59"
                textClassName="px-6 sm:px-0"
            >
                <div className='px-6 sm:px-0 flex flex-col md:flex-row items-center justify-between gap-8 xl:gap-10 mt-8 sm:mt-16'>
                    <ScrollAnimation animateIn="fadeInLeft" className='md:w-1/2'>
                        <img src={ConstructionSite} alt='Construction' className='object-contain max-h-740' />
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeInRight" className='md:w-1/2'>
                        <img src={ConstructionSite} alt='Construction' className='object-contain ' />
                    </ScrollAnimation>
                </div>
                <div className='flex flex-col gap-12 mt-8 sm:mt-16'>
                    <ProgressCard steps={stage1Steps} />
                    <ProgressCard steps={stage2Steps} />
                    <ProgressCard steps={stage2Steps} />
                </div>
            </PageIntroSection>
            {/* Content */}

            {/* Latest Posts */}
            <LatestPosts />
            {/* Latest Posts */}
        </>
    )
}

export default Progress