
const Youtube = ({ className }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="150 150 700 700" xmlSpace="preserve" width={'48px'} className={className}>
            <path fill="#D6F0F5" d="M838.6,329.9c-8.2-30.3-32.2-54.5-62.5-62.5c-55-14.9-276.1-14.9-276.1-14.9s-221.1,0-276.1,14.9  c-30.3,8.2-54.5,32.2-62.5,62.5c-14.9,55-14.9,170.1-14.9,170.1s0,115.1,14.9,170.1c8.2,30.3,32.2,54.5,62.5,62.5  c55,14.9,276.1,14.9,276.1,14.9s221.1,0,276.1-14.9c30.3-8.2,54.5-32.2,62.5-62.5c14.9-55,14.9-170.1,14.9-170.1  S853.5,384.9,838.6,329.9z M429.3,606V394L613,500L429.3,606z" />
        </svg>

    )
}

export default Youtube