import "animate.css/animate.compat.css";
import { ScrollRestoration, createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";

import News from "./Pages/News";
import Home from "./Pages/Home";
import About from "./Pages/About";
import TheLife from "./Pages/TheLife";
import Progress from "./Pages/Progress";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import ContactUs from "./Pages/ContactUs";
import Masterplan from "./Pages/Masterplan";
import NewsArticle from "./Pages/NewsArticle";
import OurPolicies from "./Pages/OurPolicies";
import DisplayHomes from "./Pages/DisplayHomes";
import LandForSaleMap from "./Pages/LandForSale";
import BuildingResources from "./Pages/BuildingResources";
import { RegistrationFormProvider } from "./Components/RegistrationFormProvider";

function App() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        <Layout>
          <Home />
        </Layout>
      ),
    },
    {
      path: '/news',
      element: (
        <Layout>
          <News />
        </Layout>
      ),
    },
    {
      path: '/about',
      element: (
        <Layout>
          <About />
        </Layout>
      ),
    },
    {
      path: '/the-life',
      element: (
        <Layout>
          <TheLife />
        </Layout>
      ),
    },
    {
      path: '/news/:id',
      element: (
        <Layout>
          <NewsArticle />
        </Layout>
      ),
    },
    {
      path: '/masterplan',
      element: (
        <Layout>
          <Masterplan />
        </Layout>
      ),
    },
    {
      path: '/progress',
      element: (
        <Layout>
          <Progress />
        </Layout>
      ),
    },
    {
      path: '/our-policies',
      element: (
        <Layout>
          <OurPolicies />
        </Layout>
      ),
    },
    {
      path: '/contact-us',
      element: (
        <Layout>
          <ContactUs />
        </Layout>
      ),
    },
    {
      path: '/land-for-sale',
      element: (
        <Layout>
          <LandForSaleMap />
      </Layout>
      ),
    },
    {
      path: '/display-homes',
      element: (
        <Layout>
          <DisplayHomes />
        </Layout>
      ),
    },
    {
      path: '/building-resources',
      element: (
        <Layout>
          <BuildingResources />
        </Layout>
      ),
    },
    {
      path: '*',  // Catch-all route for undefined paths
      element: <Navigate to="/" replace /> // Redirect to home route
    },
  ]);


  return (
    <div className="flex min-h-screen flex-col justify-between relative bg-parchment overflow-x-hidden">
      <RouterProvider router={router} />
    </div>
  );
}

const Layout = ({ children }) => (
  <RegistrationFormProvider>
    <Header />
    {children}
    <Footer />
    <ScrollRestoration />
  </RegistrationFormProvider>
)

export default App;
