import React from 'react';
import ImageCarousel from './ImageCarousel';
import HomeFeatures from './HomeFeatures';
import ContactInfo from './ContactInfo';
import BuilderDescription from './BuilderDescription';

const BuilderDetailsCard = ({
    builder,
    currentImageIndex,
    onPrevImage,
    onNextImage
}) => {
    return (
        <div className="flex flex-col lg:flex-row gap-8">
            {/* Left Column - Image Carousel */}
            <div className="lg:w-1/2 relative">
                <ImageCarousel
                    images={builder.homeImages}
                    currentIndex={currentImageIndex}
                    onPrev={onPrevImage}
                    onNext={onNextImage}
                    logo={builder.logo}
                    name={builder.name}
                    homeName={builder.homeName}
                />

                {/* Home Details */}
                <div className="mt-6">
                    <HomeFeatures
                        beds={builder.beds}
                        baths={builder.baths}
                        cars={builder.cars}
                    />

                    <ContactInfo
                        contactInfo={builder.contactInfo}
                        openHours={builder.openHours}
                    />
                </div>
            </div>

            {/* Right Column - Text Content */}
            <div className="lg:w-1/2">
                <BuilderDescription
                    description={builder.description}
                    homeName={builder.homeName}
                    homeDescription={builder.homeDescription}
                    buttonText={builder.buttonText}
                    buttonLink={builder.buttonLink}
                    virtualTourButton={builder.virtualTourButton}
                />
            </div>
        </div>
    );
};

export default BuilderDetailsCard;