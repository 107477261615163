import Radio from './Radio';
import Button from './Button';
import InputField from './InputField';
import { Link } from 'react-router-dom';
import Facebook from '../assets/images/Facebook.png';
import Instagram from '../assets/images/Instagram.png';
import { buyerTypes } from '../utils/constants';
import Select from './Select';
import Youtube from '../assets/svgs/Youtube';
import Map from '../assets/svgs/Map';
import { permanentRedirect } from '../utils/globalFunctions';
import FooterForm from './FooterForm';

export const Footer = () => {
  return (
    <>
      {/* Desktop */}
      <footer className='bg-gableGreen px-16 lg:px-28 py-16 lg:py-20 hidden md:flex items-center justify-between gap-16 lg:gap-24'>
        <div className='w-30'>
          <Link to={'/'}>
            <img src='/footer_logo.png' alt='Logo' className='w-full max-h-292 object-contain' />
          </Link>
        </div>
        <div className='w-70'>
          <div className='flex items-center gap-20'>
            <div className='self-start w-2/5'>
              <p className='font-mori font-medium text-sm xl:text-base text-polar'>
                Banyan Hill Sales Center
              </p>
              <p className='font-mori text-sm xl:text-base text-polar leading-6'>
                Ballina Heights Drive <br />Cumbalum NSW 2478
              </p>
              <div className='pt-8'>
                <p className='font-mori font-medium text-sm xl:text-base text-polar'>
                  Contact Us
                </p>
                <p className='font-mori text-sm xl:text-base text-polar -mb-1'>
                  1300 326 197
                </p>
                <p className='font-mori text-sm xl:text-base text-polar'>
                  Info@banyanhill.com.au
                </p>
              </div>
              <div className='mt-8'>
                {/* <Link to={'/building-resources'} className='font-mori text-sm xl:text-base text-polar hover:underline'>
                  Building Resources Portal
                </Link> */}
                <div className='flex items-center gap-3'>
                  <img
                    src={Instagram}
                    alt='Instagram'
                    className='w-full max-w-8 cursor-pointer'
                    onClick={() => permanentRedirect('https://www.instagram.com/banyanhill/')}
                  />
                  <img
                    src={Facebook}
                    alt='Facebook'
                    className='w-full max-w-8 cursor-pointer'
                    onClick={() => permanentRedirect('https://www.facebook.com/banyanhill')}
                  />
                  <div className='cursor-pointer'>
                    <Youtube />
                  </div>
                  <div
                    className='cursor-pointer -ml-1'
                    onClick={() => permanentRedirect("https://www.google.com.au/maps/place/28%C2%B049'01.4%22S+153%C2%B031'57.1%22E/@-28.8170573,153.5303453,17z/data=!3m1!4b1!4m14!1m7!3m6!1s0x0:0x0!2zMjjCsDQ5JzAwLjkiUyAxNTPCsDMxJzU5LjQiRQ!3b1!8m2!3d-28.816926!4d153.533179!3m5!1s0x0:0x0!7e2!8m2!3d-28.8170624!4d153.5325337?shorturl=1")}
                  >
                    <Map />
                  </div>
                </div>
              </div>
              <div className='pt-12'>
                <p className='font-mori text-sm xl:text-base text-polar flex items-center gap-2 xl:gap-4'>
                <Link className='hover:underline' to={'/our-policies'}>Privacy Policy</Link> | <Link className='hover:underline'>Disclaimers</Link> | <Link className='hover:underline' to={'/building-resources'}>Building Resources Portal</Link>
                </p>
              </div>
            </div>
            <div className='self-start w-1/2'>
              <p className='font-mori font-medium text-sm xl:text-base text-polar pb-8'>
                Keep me up to date
              </p>
              <FooterForm />
            </div>
          </div>
          <p className='text-polar text-sm xl:text-base leading-5 pt-8 font-mori'>
            We acknowledge the Bundjalung, Gumbaynggirr and Yaegl people as the Traditional Owners of the land on which we live and work. We honour the First Nations peoples' culture and connection to land, sea and community. We pay our respects to their Elders past, present and emerging.
          </p>
        </div>
      </footer>
      {/* Desktop */}

      {/* Mobile */}
      <footer className='bg-gableGreen p-8 sm:p-16 block md:hidden'>
        <div>
          <p className='font-mori font-medium text-xs sm:text-sm text-polar pb-6 sm:pb-8'>
            Keep me up to date
          </p>
          <FooterForm />
        </div>
        <div className='flex gap-3 sm:gap-6 mt-10'>
          <div className='w-1/2'>
            <p className='font-mori font-medium text-xs sm:text-sm text-polar'>
              Banyan Hill Sales Center
            </p>
            <p className='font-mori text-xs sm:text-sm text-polar mt-1 mb-6'>
              Ballina Heights Drive <br />Cumbalum NSW 2478
            </p>
            <Link to={'/'}>
              <img src='/footer_logo.png' alt='Logo' className='w-full max-w-56' />
            </Link>
          </div>
          <div className='w-1/2'>
            <p className='font-mori font-medium text-xs sm:text-sm xl:text-base text-polar'>
              Contact Us
            </p>
            <p className='font-mori text-xs sm:text-sm xl:text-base text-polar mt-1'>
              1300 326 197
            </p>
            <p className='font-mori text-xs sm:text-sm xl:text-base text-polar'>
              Info@banyanhill.com.au
            </p>
            <div className='mt-2'>
              <div className='flex items-center gap-2 sm:gap-3'>
                <img
                  src={Instagram}
                  alt='Instagram'
                  className='w-full max-w-6 sm:max-w-8 cursor-pointer'
                  onClick={() => permanentRedirect('https://www.instagram.com/banyanhill/')}
                />
                <img
                  src={Facebook}
                  alt='Facebook'
                  className='w-full max-w-6 sm:max-w-8 cursor-pointer'
                  onClick={() => permanentRedirect('https://www.facebook.com/banyanhill')}
                />
                <div className='cursor-pointer'>
                  <Youtube className='w-9 sm:w-full' />
                </div>
                <div
                  className='cursor-pointer -ml-1'
                  onClick={() => permanentRedirect("https://www.google.com.au/maps/place/28%C2%B049'01.4%22S+153%C2%B031'57.1%22E/@-28.8170573,153.5303453,17z/data=!3m1!4b1!4m14!1m7!3m6!1s0x0:0x0!2zMjjCsDQ5JzAwLjkiUyAxNTPCsDMxJzU5LjQiRQ!3b1!8m2!3d-28.816926!4d153.533179!3m5!1s0x0:0x0!7e2!8m2!3d-28.8170624!4d153.5325337?shorturl=1")}
                >
                  <Map className='w-6 sm:w-full' />
                </div>
              </div>
            </div>
            <div className='mt-2'>
              <p className='font-mori text-xs sm:text-sm xl:text-base text-polar flex items-center gap-1 sm:gap-2 xl:gap-4'>
                <Link className='hover:underline' to={'/our-policies'}>Privacy Policy</Link> | <Link className='hover:underline'>Disclaimers</Link> | <Link className='hover:underline' to={'/building-resources'}>Building Resources Portal</Link>
              </p>
            </div>
          </div>
        </div>
        <p className='text-polar text-xs sm:text-sm sm:leading-5 mt-6 font-mori'>
          We acknowledge the Bundjalung, Gumbaynggirr and Yaegl people as the Traditional Owners of the land on which we live and work. We honour the First Nations peoples' culture and connection to land, sea and community. We pay our respects to their Elders past, present and emerging.
        </p>
      </footer>
      {/* Mobile */}
    </>
  )
}

export default Footer