export const handleDownload = (url) => {
    // Create an anchor element to trigger the download
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', ''); // Let the browser determine the filename
    link.setAttribute('target', '_blank'); // Open in a new tab

    // Append the link to the body (required for Firefox)
    document.body.appendChild(link);

    // Trigger the click event on the anchor element
    link.click();

    // Remove the link from the body
    document.body.removeChild(link);
};

export const permanentRedirect = (url) => window.location.href = url;