import Button from './Button';
import React, { useState, useEffect } from 'react';
import Cross from '../assets/svgs/Cross';
import { useLocation } from 'react-router-dom';
import { useRegistrationForm } from './RegistrationFormProvider';

export const Headline = () => {
    const [show, setShow] = useState(true);
    const location = useLocation();
    const { toggleRegistrationForm } = useRegistrationForm();

    // Hide the headline on specific routes
    useEffect(() => {
        if (location.pathname === '/register') {
            setShow(false);
        } else {
            setShow(true);
        }
    }, [location.pathname]);

    // Don't render if we're on the register page
    if (location.pathname === '/register') {
        return null;
    }

    return (
        <div className={`${show ? 'block' : 'hidden'} bg-gableGreen/75 backdrop-blur-md shadow-xl fixed bottom-70 m-auto left-0 sm:left-auto right-0 sm:right-10 xl:right-59 p-6 sm:p-10 w-90 sm:w-full sm:max-w-450 xl:max-w-548 z-20`}>
            <div className='flex justify-between items-center'>
                <p className='font-tobias text-honeysuckle text-3xl sm:text-4xl lg:text-5xl'>New Land Release</p>
                <div className='cursor-pointer' onClick={() => setShow(false)}>
                    <Cross className='w-4 sm:w-6 xl:w-8' />
                </div>
            </div>
            <p className='font-mori text-honeysuckle text-sm sm:text-base lg:text-lg leading-5 lg:leading-6 my-4 sm:my-6'>
                Our new release is coming soon. Lots range from 476m2 to 900m2. Register now for updates
            </p>
            <Button
                title='Register Now'
                className='hover:bg-honeysuckle hover:text-gableGreen'
                onClick={toggleRegistrationForm}
            />
        </div>
    )
}

export default Headline