import Button from './Button';
import Dropdown from './Dropdown';
import Headline from './Headline';
import EnquireForm from './EnquireForm';
import MobileNavbar from './MobileNavbar';
import { routes } from '../utils/constants';
import Hamburger from '../assets/svgs/Hamburger';
import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

export const Header = () => {
    const location = useLocation();
    const darkHeaderPaths = ['/our-policies', '/contact-us'];
    const [isDarkHeader, setIsDarkHeader] = useState(false)

    const [openMobileMenu, setOpenMobileMenu] = useState(false);
    const [openEnquireForm, setOpenEnquireForm] = useState(false);

    const toggleNavbar = () => setOpenMobileMenu(prev => !prev);
    const toggleEnquireForm = () => setOpenEnquireForm(prev => !prev);

    const [showNavbar, setShowNavbar] = useState(true);
    const [isScrolled, setIsScrolled] = useState(false);
    const [lastScrollY, setLastScrollY] = useState(0);
    const darkNav = isScrolled || isDarkHeader;

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;
            // Check if the user scrolled more than 0 pixels
            if (currentScrollY > 100) {
                setIsScrolled(true); // Set to true if scrolled
            } else {
                setIsScrolled(false); // Set to false if not scrolled
            }
            if (currentScrollY > lastScrollY) {
                // Scrolling down
                setShowNavbar(false);
            } else {
                // Scrolling up
                setShowNavbar(true);
            }

            setLastScrollY(currentScrollY);
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup function to remove the event listener
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollY]);

    useEffect(() => {
        setIsDarkHeader(darkHeaderPaths.includes(location.pathname) || location.pathname.includes('/news/') ||
          location.pathname.includes('/land-for-sale'));
        if (openMobileMenu) setOpenMobileMenu(false);
        // eslint-disable-next-line
    }, [location.pathname])

    return (
        <>
            <header className={`px-6 sm:px-10 xl:px-59 pt-7 md:pt-38 fixed w-full z-10 transition-all duration-300 ease-in-out ${isScrolled ? 'lg:border-b bg-parchment/65 backdrop-blur-lg pb-7 md:pb-38' : ''} ${showNavbar ? 'translate-y-0' : !openMobileMenu ? '-translate-y-full' : ''}`}>
                <div className='flex items-center justify-between'>
                    <NavLink to={'/'}>
                        {darkNav ?
                            <img src='/dark_logo.png' alt='Logo' className='max-w-40 md:max-w-56 transition-opacity duration-300' />
                            :
                            <img src='/logo.png' alt='Logo' className='max-w-40 md:max-w-56 transition-opacity duration-300' />
                        }
                    </NavLink>
                    <div className='hidden lg:flex items-center gap-4 xl:gap-6 2xl:gap-10'>
                        <ul className={`font-mori ${darkNav ? 'text-gableGreen' : 'text-honeysuckle'} flex items-center transition-colors duration-300`}>
                            {routes.filter(route => !route.isHidden).map((route, i) => (
                                route.type === 'select' ?
                                    <li className='pl-4 xl:pl-6 2xl:pl-10' key={i}>
                                        <Dropdown dark={darkNav} route={route} />
                                    </li>
                                    :
                                    <li key={i}>
                                        <NavLink
                                            to={route.path}
                                            className={({ isActive }) =>
                                                [
                                                    "hover:underline pl-4 xl:pl-6 2xl:pl-10 text-base 2xl:text-lg transition-all duration-300",
                                                    isActive ? "underline" : "",
                                                ].join(" ")
                                            }
                                        >
                                            {route.name}
                                        </NavLink>
                                    </li>
                            ))}
                        </ul>
                        <Button
                            title='Enquire Now'
                            onClick={toggleEnquireForm}
                            variant={darkNav ? 'gableGreen' : 'honeysuckle'}
                        />
                    </div>
                    <div className='block lg:hidden cursor-pointer' onClick={toggleNavbar}>
                        <Hamburger isDark={darkNav} />
                    </div>
                    {openMobileMenu && <MobileNavbar toggleNavbar={toggleNavbar} toggleEnquireForm={toggleEnquireForm} />}
                </div>
                {(isDarkHeader && !isScrolled) && <div className='border-b border-gableGreen pb-7 sm:pb-38 transition-opacity duration-300' />}
            </header>
            {openEnquireForm && <EnquireForm toggleEnquireForm={toggleEnquireForm} />}
            <Headline />
        </>
    )
}

export default Header