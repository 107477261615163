import Button from './Button';

export const Banner = ({ bgImage = '', title, subtitle, buttonText, buttonLink }) => {
    return (
        <section className={`relative ${bgImage} bg-no-repeat bg-cover bg-center h-80 lg:h-500 2xl:h-600 flex justify-end flex-col pb-8 lg:pb-8`}>
            <div className='px-6 sm:px-10 xl:px-59'>
                <p className='font-tobias text-honeysuckle text-5xl sm:text-6xl lg:text-88 2xl:text-116'>{title}</p>
                {subtitle && <p className='text-white text-base sm:text-lg mt-2 max-w-2xl'>{subtitle}</p>}
                {buttonText && buttonLink && (
                    <div className='mt-6'>
                        <Button
                            variant='turquoise'
                            title={buttonText}
                            onClick={() => window.location.href = buttonLink}
                            className='text-sm'
                        />
                    </div>
                )}
            </div>
            <div className='top-0 w-full absolute bg-gradient-to-b h-1/2 from-lightBlack to-transparent' />
        </section>
    )
}

export default Banner;