
const ArrowDown = ({ className = '' }) => {
    return (
        <svg
            className={`w-6 h-6 ${className}`}
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
        >
            <path
                fillRule="evenodd"
                d="M5.23 7.21a.75.75 0 011.06.02L10 10.84l3.72-3.61a.75.75 0 011.08 1.04l-4.25 4.12a.75.75 0 01-1.06 0l-4.25-4.12a.75.75 0 01.02-1.06z"
                clipRule="evenodd"
            />
        </svg>
    )
}

export default ArrowDown