import Sky from '../assets/images/Sky.png';
import Seaside from '../assets/images/seaside.png';
import Location from '../assets/images/location_view.png';
import GeneralStore from '../assets/images/general_store.png';
import ScrollAnimation from 'react-animate-on-scroll';
import {
    Card,
    Button,
    Banner,
    LatestPosts,
    PageIntroSection
} from '../Components';

const TheLife = () => {
    return (
        <>
            {/* Banner */}
            <Banner
                title={'The Life'}
                bgImage='bg-life-banner'
            />
            {/* Banner */}

            {/* Content */}
            <PageIntroSection
                text="Shops, restaurants, sports, schools, healthcare, you'll never need to go far to provide the kind of life you want for your loved ones. They are just down the hill and a little way up the road. Even closer to home, there will be a Village Green, including a community centre and garden. Whether it's people gathering to meet, celebrate, or even share those organic veggies they've been growing—it will all happen here, right by the Moreton Bay Fig tree."
            >
                <ScrollAnimation animateIn="zoomIn">
                    <img src={Location} alt='Location View' className='max-h-740 w-full object-cover mt-12 lg:mt-16 2xl:mt-20' />
                </ScrollAnimation>
                <Button
                    variant='gableGreen'
                    title='Download Location'
                    onClick={() => window.open(require('../assets/pdfs/BanyanHill_Amenity_Map.pdf'), '_blank')}
                    className='mt-4 sm:mt-8 xl:mt-10 text-sm 2xl:!text-base'
                />

                <div className={cardCotainer}>
                    <Card
                        image={Seaside}
                        heading="A home by the world's best beaches"
                        text="Breathe the salt air. The surf's within your reach. The sand's beneath your feet. While the spray, carried on the breeze, reminds you how close the sea is. Join the line-up at dawn for amazing sunrises. Or safely swim between the flags on lifeguard patrolled shorelines. Life's a beach in these parts and you're spoilt for choice."
                    />
                </div>
                <div className={cardCotainer}>
                    <Card
                        image={GeneralStore}
                        showImageFirst={false}
                        heading="Coastal living at its absolute finest"
                        text="People travel far and wide for this coastal life, the nature walks, the thriving cafe and restaurant scene, for the artisans and the bartering at markets for unique pieces. But, unlike those who need to prise themselves away, planning their return before they board the plane, you can remain and wake every day to all this."
                    />
                </div>
                <div className={cardCotainer}>
                    <Card
                        image={Sky}
                        heading="The magical hinterland awaits"
                        text="The coastal towns and beaches on one side and the allure of something altogether different on the other. The Ballina and Byron Hinterland is a rolling and enchanted maze of green valleys, cute local streetscapes and towns and more surprises than you can imagine. And it's all right here, on the doorstep of Banyan Hill."
                    />
                </div>
            </PageIntroSection>
            {/* Content */}

            {/* Latest Posts */}
            <LatestPosts />
            {/* Latest Posts */}
        </>
    )
}
const cardCotainer = 'mt-8 sm:mt-10 lg:mt-16 xl:mt-20';

export default TheLife