
const Hamburger = ({ isDark }) => {
    const color = isDark ? "#123332" : "#E9FF86";
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"  height="50" className="h-9 md:h-12" viewBox="0 0 12 12" enableBackground="new 0 0 12 12" id="Слой_1" version="1.1" xmlSpace="preserve">
            <g>
                <rect fill={color} height="0.6" width="11" x="0.5" y="5.5" />
                <rect fill={color} height="0.6" width="11" x="0.5" y="2.5" />
                <rect fill={color} height="0.6" width="11" x="0.5" y="8.5" />
            </g>
        </svg>
    )
}

export default Hamburger