import React, { useState, useRef, useEffect } from 'react';
import Banner from '../Components/Banner';
import LatestPosts from '../Components/LatestPosts';
import ScrollAnimation from 'react-animate-on-scroll';

// Import our new components
import {
    BuilderSelector,
    BuilderDetailsCard,
    CallToAction,
    IntroSection,
    DisplayVillageMap,
    buildersData
} from '../Components/DisplayHomes';

const DisplayHomes = () => {
    // State to track which builder is selected
    const [selectedBuilder, setSelectedBuilder] = useState('gjgardner');
    // State to track current image index for each builder
    const [currentImageIndex, setCurrentImageIndex] = useState({
        gjgardner: 0,
        perryhomes: 0,
        coralhomes: 0
    });

    // Create a ref for the builder section
    const builderSectionRef = useRef(null);

    // Get the currently selected builder data
    const currentBuilder = buildersData[selectedBuilder];

    // Function to handle logo click
    const handleLogoClick = (builderId) => {
        setSelectedBuilder(builderId);

        // Scroll to the builder section with smooth behavior
        if (builderSectionRef.current) {
            builderSectionRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        }
    };

    // Function to handle next image
    const handleNextImage = () => {
        setCurrentImageIndex({
            ...currentImageIndex,
            [selectedBuilder]: (currentImageIndex[selectedBuilder] + 1) % currentBuilder.homeImages.length
        });
    };

    // Function to handle previous image
    const handlePrevImage = () => {
        setCurrentImageIndex({
            ...currentImageIndex,
            [selectedBuilder]: (currentImageIndex[selectedBuilder] - 1 + currentBuilder.homeImages.length) % currentBuilder.homeImages.length
        });
    };

    return (
        <>
            {/* Banner */}
            <Banner
                title={'Display Village Now Open'}
                bgImage='bg-display-home-banner'
            />
            {/* Banner */}

            {/* Content */}
            <section className='px-6 sm:px-10 xl:px-59 pt-8 sm:pt-10 lg:pt-14 2xl:pt-20 pb-8 sm:pb-16 lg:pb-16'>
                <IntroSection />

            </section>

            {/* Builder Logos - Interactive Buttons */}
            <section id="builder-section" ref={builderSectionRef}>
                <BuilderSelector
                    builders={buildersData}
                    selectedBuilder={selectedBuilder}
                    onBuilderSelect={handleLogoClick}
                />

                {/* Builder Content Section - Fixed Height Container */}
                <div className={cardContainer}>
                    <div className={fixedHeightContainer}>
                        <BuilderDetailsCard
                            builder={currentBuilder}
                            currentImageIndex={currentImageIndex[selectedBuilder]}
                            onPrevImage={handlePrevImage}
                            onNextImage={handleNextImage}
                        />
                    </div>
                </div>
            </section>

            {/* Australia's Best Builders Section */}
            {/* <section className='bg-honeysuckle-light px-6 sm:px-10 xl:px-59 pt-12 sm:pt-16 lg:pt-20 pb-12 sm:pb-20 lg:pb-16'>
                <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                    <CallToAction />
                </ScrollAnimation>
            </section> */}
            {/* Content */}

            {/* Display Village Map Section */}
            <section>
                <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                    <DisplayVillageMap />
                </ScrollAnimation>
            </section>

            {/* Latest Posts with improved styling */}
            <LatestPosts />
        </>
    )
}

const cardContainer = 'p-8 sm:p-10 lg:p-16 xl:p-20 bg-white w-full';
const fixedHeightContainer = 'overflow-auto transition-all duration-300 ease-in-out';

export default DisplayHomes