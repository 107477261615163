import { Link } from 'react-router-dom';

export const NewsCard = ({ news }) => {
    return (
        <div className='flex flex-col gap-5 items-center'>
            <div className={`w-full`}>
                <img src={news.image} className='max-w-900 w-full max-h-400 object-cover' />
            </div>
            <div className={`w-full`}>
                <p className='text-cuttySark font-medium font-mori text-xs mb-1 sm:mb-2 md:mb-1 lg:mb-2'>{news.createdAt}</p>
                {/* <h4 className='font-tobias text-xl sm:text-3xl md:text-xl xl:text-2xl text-gableGreen'>{news.title}</h4> */}
                {<h4 className='font-tobias text-xl sm:text-3xl md:text-xl xl:text-2xl text-gableGreen cursor-pointer hover:underline'>
                    <Link to={`/news/${news.id}`}>
                        {news.title}
                    </Link>
                </h4>}
                <p className='font-mori text-xs sm:text-base md:text-xs xl:text-sm leading-5 mt-1 sm:mt-4 md:mt-1 lg:mt-2 sm:mb-1 md:mb-0 lg:mb-1'>
                    {news.intro}
                </p>
                <Link to={`/news/${news.id}`} className='font-mori border-b text-xs font-medium border-gableGreen text-gableGreen'>Read more</Link>
            </div>
        </div>
    )
}

export default NewsCard