import React, { useState, useRef } from 'react';
import InputField from './InputField';
import Select from './Select';
import Radio from './Radio';
import Button from './Button';
import { Link } from 'react-router-dom';
import { buyerTypes } from '../utils/constants';
import ReCAPTCHA from 'react-google-recaptcha';

export const FooterForm = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        buyerType: 'Buyer Type'
    });
    const [isChecked, setIsChecked] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [errors, setErrors] = useState({});
    const [recaptchaToken, setRecaptchaToken] = useState('');
    const recaptchaRef = useRef(null);

    const handleInputChange = (field, value) => {
        setFormData(prev => ({
            ...prev,
            [field]: value
        }));
        // Clear error when user starts typing
        if (errors[field]) {
            setErrors(prev => ({
                ...prev,
                [field]: ''
            }));
        }
    };

    const handleRecaptchaChange = (token) => {
        setRecaptchaToken(token);
        if (errors.recaptcha) {
            setErrors(prev => ({
                ...prev,
                recaptcha: ''
            }));
        }
    };

    const validateForm = () => {
        const newErrors = {};

        // Basic email validation
        if (formData.email) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(formData.email)) {
                newErrors.email = 'Please enter a valid email address';
            }
        } else {
            newErrors.email = 'Email is required';
        }

        // Buyer type validation
        if (formData.buyerType === 'Buyer Type') {
            newErrors.buyerType = 'Please select a buyer type';
        }

        // Privacy policy check
        if (!isChecked) {
            newErrors.privacy = 'You must agree to the privacy policy';
        }

        // reCAPTCHA validation
        if (!recaptchaToken) {
            newErrors.recaptcha = 'Please complete the reCAPTCHA verification';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        if (e) e.preventDefault();

        if (validateForm()) {
            setIsSubmitting(true);

            try {
                // Create form data for submission
                const formDataToSubmit = new URLSearchParams();
                formDataToSubmit.append('firstName', formData.firstName);
                formDataToSubmit.append('lastName', formData.lastName);
                formDataToSubmit.append('email', formData.email);
                formDataToSubmit.append('buyerType', formData.buyerType);
                formDataToSubmit.append('formSource', 'Footer');

                // Add reCAPTCHA token
                formDataToSubmit.append('g-recaptcha-response', recaptchaToken);

                // API endpoint from environment variable
                const apiUrl = `${process.env.REACT_APP_API_URL}/enquire`;

                const response = await fetch(apiUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: formDataToSubmit,
                });

                if (response.ok) {
                    // Success handling
                    setIsSubmitted(true);
                    setIsSubmitting(false);

                    // Reset form
                    setFormData({
                        firstName: '',
                        lastName: '',
                        email: '',
                        buyerType: 'Buyer Type'
                    });
                    setIsChecked(false);
                    setRecaptchaToken('');
                    if (recaptchaRef.current) {
                        recaptchaRef.current.reset();
                    }

                    // Show success message for 3 seconds
                    setTimeout(() => {
                        setIsSubmitted(false);
                    }, 3000);
                } else {
                    const errorData = await response.json();
                    console.error('Form submission error:', errorData);
                    setIsSubmitting(false);
                    setErrors(prev => ({
                        ...prev,
                        general: errorData.message || 'Something went wrong. Please try again.'
                    }));
                }
            } catch (error) {
                console.error('Form submission error:', error);
                setIsSubmitting(false);
                setErrors(prev => ({
                    ...prev,
                    general: 'Something went wrong. Please try again.'
                }));
            }
        }
    };

    return (
        <div className="flex flex-col gap-6">
            {isSubmitted ? (
                <div className="bg-polar bg-opacity-20 px-4 py-3 rounded text-center">
                    <p className="text-polar text-sm">Thank you for your interest! We'll be in touch soon.</p>
                </div>
            ) : (
                <>
                    <InputField
                        placeholder="First Name"
                        value={formData.firstName}
                        onChange={(e) => handleInputChange('firstName', e.target.value)}
                    />
                    <InputField
                        placeholder="Last Name"
                        value={formData.lastName}
                        onChange={(e) => handleInputChange('lastName', e.target.value)}
                    />
                    <div>
                        <InputField
                            placeholder="Email"
                            value={formData.email}
                            onChange={(e) => handleInputChange('email', e.target.value)}
                        />
                        {errors.email && <span className="text-red-300 text-xs mt-1 block">{errors.email}</span>}
                    </div>
                    <div>
                        <Select
                            color="polar"
                            defaultValue={formData.buyerType}
                            options={buyerTypes}
                            onChange={(value) => handleInputChange('buyerType', value)}
                        />
                        {errors.buyerType && <span className="text-red-300 text-xs mt-1 block">{errors.buyerType}</span>}
                    </div>
                    <div className="flex gap-5 xl:gap-7 items-center">
                        <div onClick={() => setIsChecked(!isChecked)}>
                            <Radio checked={isChecked} onChange={() => setIsChecked(!isChecked)} />
                        </div>
                        <label className="text-sm xl:text-base font-mori text-polar">
                            I agree to the <Link to={'/our-policies'} className='underline'>Privacy Policy</Link>
                        </label>
                    </div>
                    {errors.privacy && <span className="text-red-300 text-xs -mt-4">{errors.privacy}</span>}

                    <div className="mt-2 flex justify-center">
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                            onChange={handleRecaptchaChange}
                            theme="dark"
                            size="compact"
                        />
                    </div>
                    {errors.recaptcha && <span className="text-red-300 text-xs text-center">{errors.recaptcha}</span>}

                    {errors.general && <span className="text-red-300 text-xs text-center">{errors.general}</span>}
                    <Button
                        variant="polar"
                        className="w-fit !text-sm px-12"
                        title={isSubmitting ? "Submitting..." : "Submit"}
                        onClick={handleSubmit}
                        disabled={isSubmitting}
                    />
                </>
            )}
        </div>
    );
};

export default FooterForm;