import { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Banner from '../Components/Banner';
import Button from '../Components/Button';
import NewsCard from '../Components/NewsCard';
import LatestPosts from '../Components/LatestPosts';
import ScrollAnimation from 'react-animate-on-scroll';
import api from '../services/api';

const News = () => {
    const [newsData, setNewsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);

    useEffect(() => {
        fetchNews();
    }, []);

    const fetchNews = async () => {
        try {
            setLoading(true);
            const data = await api.getNews();
            setNewsData(data);
            setLoading(false);
        } catch (err) {
            setError('Failed to load news');
            setLoading(false);
        }
    };

    const loadMore = async () => {
        try {
            setLoading(true);
            const nextPage = page + 1;
            const moreNews = await api.getNews(nextPage);

            if (moreNews.length === 0) {
                setHasMore(false);
            } else {
                setNewsData([...newsData, ...moreNews]);
                setPage(nextPage);
            }
            setLoading(false);
        } catch (err) {
            setError('Failed to load more news');
            setLoading(false);
        }
    };

    const latestArticle = newsData.find(x => x.latest) || (newsData.length > 0 ? newsData[0] : null);
    const otherNews = newsData.filter(x => !x.latest && x.id !== latestArticle?.id);

    if (loading && newsData.length === 0) {
        return <div className="flex justify-center items-center min-h-screen">Loading...</div>;
    }

    if (error && newsData.length === 0) {
        return <div className="flex justify-center items-center min-h-screen text-red-500">{error}</div>;
    }

    return (
        <>
            {/* Banner */}
            <Banner
                title={'News updates'}
                bgImage='bg-news-banner'
            />
            {/* Banner */}

            {/* Content */}
            <section className='px-6 sm:px-10 xl:px-59 pt-10 sm:pt-12 lg:pt-16 2xl:pt-20 pb-8 sm:pb-12 lg:pb-24 xl:pb-28'>
                {latestArticle && (
                    <div className='flex flex-col md:flex-row gap-5 lg:gap-10 items-center border-b border-gableGreen pb-5 md:pb-10'>
                        <ScrollAnimation animateIn='lightSpeedInLeft' className={`w-full md:w-1/2`}>
                            <img src={latestArticle.image} alt='Featured' className='max-w-900 w-full max-h-400 md:max-h-max object-cover' />
                        </ScrollAnimation>
                        <ScrollAnimation animateIn='lightSpeedInRight' className={`w-full md:w-1/2`}>
                            <p className='text-cuttySark font-medium font-mori text-xs lg:text-sm mb-2 md:mb-4'>{latestArticle.createdAt}</p>
                            {/* <h4 className='font-tobias text-2xl sm:text-3xl xl:text-4xl 2xl:text-5xl text-gableGreen'>{latestArticle.title}</h4> */}
                            <h4 className='font-tobias text-2xl sm:text-3xl xl:text-4xl 2xl:text-5xl text-gableGreen'>
                                <Link to={`/news/${latestArticle.id}`} className="cursor-pointer hover:underline transition-colors">
                                    {latestArticle.title}
                                </Link>
                            </h4>
                            <p className='font-mori text-sm sm:text-base 2xl:text-xl leading-5 2xl:leading-6 mt-2 sm:mt-4 lg:mt-6 mb-1 md:mb-2 xl:mb-4'>
                                {latestArticle.intro}
                            </p>
                            <Link to={`/news/${latestArticle.id}`} className='font-mori border-b text-xs md:text-sm 2xl:text-base font-medium border-gableGreen text-gableGreen'>Read more</Link>
                        </ScrollAnimation>
                    </div>
                )}

                <div className="flex flex-wrap gap-4 md:gap-6 md:py-10">
                    {otherNews.map((item) => (
                        <ScrollAnimation
                            key={item.id}
                            animateIn='fadeInUp'
                            className='w-full md:w-[31%] flex'
                        >
                            <NewsCard news={item} className="w-full flex flex-col" />
                        </ScrollAnimation>
                    ))}
                </div>

                {hasMore && (
                    <div className='w-full text-center mt-5 md:mt-10'>
                        <Button
                            title={loading ? 'Loading...' : 'Load More'}
                            className='!px-8 2xl:!px-10'
                            variant='gableGreen'
                            onClick={loadMore}
                            disabled={loading}
                        />
                    </div>
                )}
            </section>
            {/* Content */}

            {/* Latest Posts */}
            <LatestPosts />
            {/* Latest Posts */}
        </>
    )
}

export default News