import React from 'react';

const ContactInfo = ({ contactInfo, openHours }) => {
    return (
        <p className="mt-4 text-gableGreen whitespace-pre-line font-mori text-base sm:text-lg leading-6 2xl:leading-8">
            {contactInfo}
            <br /><br />
            {openHours}
        </p>
    );
};

export default ContactInfo;